import React, { useEffect, useMemo, useState } from "react";
import BackdropLoad from "../../../components/Backdrop/BackdropLoad.js";
import { getToken } from "../../../untils/shortcut.js";
import ClaimCloseCard from "./components/ClaimCloseCard.js";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { GETCLAIMCLOSE, POST, SENDCANCELCLAIMCLOSE, SENDCLAIMCLOSE } from "../../../service.js";
import { debounce } from "@mui/material";

function ClaimToCloseRights() {
    const profile = getToken(localStorage.getItem("profile"));
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [dateStart, setDateStart] = useState(dayjs().startOf("month"));
    const [dateEnd, setDateEnd] = useState(dayjs());
    const [search, setSearch] = useState("");
    const [statusSend, setStatusSend] = useState(99);
    const [type, setType] = useState(99);
    const [status, setStatus] = useState(3);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const GetDataClaimClose = async () => {
        try {
            setLoading(true);

            let res = await POST(GETCLAIMCLOSE, {
                date_start: dateStart,
                date_end: dateEnd,
                status_claim: statusSend,
                text: search,
            });
            if (res.success) {
                let data = [];
                let data_claim = res.result;

                data_claim.map((e, i) =>
                    data.push({
                        ...e,
                        check: false,
                        status: 0,
                        index: i,
                    })
                );
                //  setPage(0);
                setData(data);
                //   console.log("data1",data);
                setLoading(false);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const getClaim = async () => {
        try {
            setLoading(true);
            const payload = {
                date_start: dateStart,
                date_end: dateEnd,
                status_c: status,
                text: search,
                claim_type: type,
                status_claim: statusSend,
            };
            let res = await POST(GETCLAIMCLOSE, payload);
            if (res.success) {
                let data = [];
                let data_claim = res.result;

                data_claim.map((e, i) =>
                    data.push({
                        ...e,
                        check: false,
                        status: 0,
                        index: i,
                    })
                );
                setPage(0);
                setData(data);
                setLoading(false);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const handleChangeCheckbox = (event) => {
        let id = event.target.value;
        console.log("id", id);
        // let name = event.target.name;
        // console.log(event.target.name);

        if (event.target.name === "ทั้งหมด") {
            // let data = []
            data.map((e) =>
                setData((prevData) =>
                    prevData.map((item) =>
                        Number(item.index) === Number(e.index)
                            ? { ...item, check: event.target.checked, status: event.target.checked === true ? 1 : 0 }
                            : item
                    )
                )
            );
            // setData(data)
        } else {
            setData((prevData) =>
                prevData.map((item) =>
                    Number(item.index) === Number(id) ? { ...item, check: event.target.checked, status: event.target.checked === true ? 1 : 0 } : item
                )
            );
        }
    };

    const handleSendClaim = (item) => {
        let res_claim = data.filter((e) => e.check === true);
        let data_send_claim = [];
        res_claim.map((e) =>
            data_send_claim.push({
                SEQ: e.SEQ,
                seq_close: e.seq_close,
            })
        );

        if (data_send_claim.length > 0) {
            if (item === "authen") {
                Swal.fire({
                    title: "ยกเลิกปิดสิทธิ์",
                    text: "คุณต้องการยกเลิกปิดสิทธิ์หรือไม่?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "ยืนยัน",
                    confirmButtonColor: "green",
                    cancelButtonText: "ยกเลิก",
                    reverseButtons: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        SendAuthen(data_send_claim);
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire({
                            text: "ยกเลิกรายการแล้ว",
                            confirmButtonText: "ตกลง",
                        });
                    }
                });
            } else {
                if (statusSend > 0) {
                    Swal.fire({
                        title: "ยืนยันปิดสิทธิ์",
                        text: "ตรวจสอบรายการในเว็บเคลมปิดสิทธิ์ก่อนจะส่งออกข้อมูลซ้ำ",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "ยืนยัน",
                        confirmButtonColor: "green",
                        cancelButtonText: "ยกเลิก",
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            SendClaim(data_send_claim);
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                            Swal.fire({
                                text: "ยกเลิกรายการแล้ว",
                                confirmButtonText: "ตกลง",
                            });
                        }
                    });
                } else {
                    Swal.fire({
                        title: "ยืนยันปิดสิทธิ์",
                        text: "คุณต้องการส่งออกข้อมูลการยืนยันปิดสิทธิ์หรือไม่?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "ยืนยัน",
                        confirmButtonColor: "green",
                        cancelButtonText: "ยกเลิก",
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            SendClaim(data_send_claim);
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                            Swal.fire({
                                text: "ยกเลิกรายการแล้ว",
                                confirmButtonText: "ตกลง",
                            });
                        }
                    });
                }
            }
        } else {
            Swal.fire({
                icon: "warning",
                title: `ไม่มีข้อมูลส่งออก`,
                text: "กรุณาเลือกข้อมูลส่งออกอย่างน้อย 1 รายการ",
                confirmButtonText: "ตกลง",
            });
        }
    };

    const SendClaim = async (obj) => {
        if (profile.option_claim === null) {
            Swal.fire({
                icon: "warning",
                text: `กรุณาเลือกรูปแบบการเคลมก่อนส่งเคลม`,
                confirmButtonText: "ตกลง",
            });
        } else {
            try {
                setLoading(true);
                let payload = {
                    date_start: dateStart,
                    date_end: dateEnd,
                    seq: obj.map((item) => item.SEQ),
                };
                // console.log("ค่า SEQ: ",obj.map((item)=>item.SEQ))
                let res = await POST(SENDCLAIMCLOSE, payload);
                if (res.success) {
                    setLoading(false);
                    Swal.fire({
                        icon: "success",
                        text: res.result,
                        confirmButtonText: "ตกลง",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // window.location.reload();
                            getClaim();
                        }
                    });
                } else {
                    setLoading(false);
                    Swal.fire({
                        icon: "warning",
                        text: `${res.message}`,
                        showConfirmButton: true,
                    });
                }
            } catch (error) {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${error}`,
                    confirmButtonText: "ตกลง",
                });
            }
        }
    };

    const SendAuthen = async (item) => {
        try {
            setLoading(true);
            let payload = {
                date_start: dateStart,
                date_end: dateEnd,
                data: item.map((e) => ({ seq: e.SEQ, seq_close: e.seq_close })),
            };
            let res = await POST(SENDCANCELCLAIMCLOSE, payload);
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    text: res.result,
                    confirmButtonText: "ตกลง",
                }).then((result) => {
                    if (result.isConfirmed) {
                        // window.location.reload();
                        getClaim();
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const fetch = useMemo(
        () =>
            debounce(async (request, date_start, date_end, status, type, statusSend) => {
                try {
                    setLoading(true);
                    const payload = {
                        date_start: date_start,
                        date_end: date_end,
                        status_c: status,
                        text: request,
                        claim_type: type,
                        status_claim: statusSend,
                    };
                    let res = await POST(GETCLAIMCLOSE, payload);
                    if (res.success) {
                        let data = [];
                        let data_claim = res.result;
                        data_claim.map((e, i) =>
                            data.push({
                                ...e,
                                check: false,
                                status: 0,
                                index: i,
                            })
                        );
                        setData(data);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        Swal.fire({
                            icon: "warning",
                            text: `${res.message}`,
                            showConfirmButton: true,
                        });
                    }
                } catch (error) {
                    setLoading(false);
                    Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
                }
            }, 1500),
        []
    );

    useEffect(() => {
        GetDataClaimClose();
    }, []);

    useEffect(() => {
        if (search.length >= 3) {
            fetch(search, dateStart, dateEnd, status, type, statusSend);
        } else if (!search) {
            getClaim();
            return undefined;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, fetch, dateStart, dateEnd, status, type, statusSend]);

    return (
        <>
            <BackdropLoad loading={loading} />
            <ClaimCloseCard
                handleSendClaim={handleSendClaim}
                dateStart={dateStart}
                dateEnd={dateEnd}
                setDateStart={setDateStart}
                setDateEnd={setDateEnd}
                search={search}
                setSearch={setSearch}
                setStatusSend={setStatusSend}
                statusSend={statusSend}
                data={data}
                checkAll={data.findIndex((x) => x.status === 0)}
                handleChangeCheckbox={handleChangeCheckbox}
                type={type}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </>
    );
}

export default ClaimToCloseRights;
