import { Box, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import GridTable from "./GridTable";
import { headerTable } from "../../../untils/static";

function ListDebtor() {
    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={12} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            {/* <DeviceIcon fontSize='medium' /> */}
                            <Typography className="main-header">ตั้งลูกหนี้</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} my={2}>
                        <TextField
                            fullWidth
                            id="search"
                            label="ค้นหารายการ"
                            type="search"
                            variant="outlined"
                            size="medium"
                            // onChange={}
                            // value={}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} my={2}>
                        <GridTable
                            header={headerTable.Debtor_header}
                            menu={headerTable.Debtor_menu}
                            menu2={headerTable.Debtor_menu_2}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

export default ListDebtor;
