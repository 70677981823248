import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { GET, GETDATAMONITORINPUT, POST, SELECTORHOSP } from "../../service";
import MonitorCard from "./components/MonitorCard";

function Monitor2() {
    const [data, setData] = useState();
    const [dataLength, setDataLength] = useState("");
    const [hcode, setHcode] = useState("");
    const [hosp, setHosp] = useState([]);
    const [searchLoca, setSearchLoca] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [dateStart, setDateStart] = useState(dayjs());
    const [loading, setLoading] = useState(false);

    const getData = async () => {
        try {
            setLoading(true);
            let res = await POST(GETDATAMONITORINPUT, { hcode, date: dateStart });
            if (res.success) {
                setData(res.result.data);
                setDataLength(res.result.dataLength);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const getSelectHosp = async () => {
        try {
            setLoading(true);
            let res = await GET(SELECTORHOSP);
            if (res.success) {
                setHosp(res.result);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    useEffect(() => {
        getData();
    }, [hcode, dateStart]);

    useEffect(() => {
        getSelectHosp();
    }, []);

    return (
        <>
            <MonitorCard
                data={data}
                dataLength={dataLength}
                dataHosp={hosp}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                dateStart={dateStart}
                setDateStart={setDateStart}
                searchLoca={searchLoca}
                setSearchLoca={setSearchLoca}
                setHcode={setHcode}
            />
        </>
    );
}

export default Monitor2;
