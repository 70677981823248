import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { DELETEHR, GET, GETDATAHRTBL, POST, SELECTAMP, SELECTORHR } from "../../service";
import { getToken } from "../../untils/shortcut";
import DialogAdd from "./components/DialogAdd";
import DialogEdit from "./components/DialogEdit";
import HRTblCard from "./components/HRTblCard";

function HRTable() {
    const token = localStorage.getItem("uuid");
    const pathapi = process.env.REACT_APP_SERVICE;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [header, setHeader] = useState([]);
    const [name, setName] = useState("");
    const [position, setPosition] = useState("");
    const [level, setLevel] = useState();
    const [salary, setSalary] = useState();
    const [tel, setTel] = useState("");
    const [parent, setParent] = useState();
    const [staffId, setStaffId] = useState();
    const [basename, setBasename] = useState("");
    const [address, setAddress] = useState("");
    const [ampCode, setAmpCode] = useState("");
    const [hcode, setHcode] = useState("");
    const [hname, setHname] = useState("");
    const [selectHR, setSelectHR] = useState();
    const [selectLevel, setSelectLevel] = useState();
    const [selectParent, setSelectParent] = useState();
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [fileUpload, setFileUpload] = useState(null);
    const [amphur, setAmphur] = useState("");
    const [dataAmp, setDataAmp] = useState([]);
    const [dataHosp, setDataHosp] = useState([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const profile = getToken(localStorage.getItem("profile"));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [userForm, setUserForm] = useState({
        name: "",
        position: "",
        level: "",
        salary: "",
        tel: "",
        parent: "",
        staffId: "",
        basename: "",
        address: "",
        image_path: "",
        old_img_file: "",
    });

    const getDataAll = async () => {
        try {
            setLoading(true);
            let res = await POST(GETDATAHRTBL, { ampCode, hcode, page, rowsPerPage });
            if (res.success) {
                if (profile.role_id !== 1 && profile.role_id !== 2) {
                    setData(res.result.hr_data[0]);
                    setHeader(res.result.header[0]);
                }
                setData(res.result.hr_data);
                setHeader(res.result.header);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const getSelectHR = async () => {
        try {
            setLoading(true);
            let res = await GET(SELECTORHR);
            if (res.success) {
                setSelectHR(res.result.position);
                setSelectLevel(res.result.level);
                setSelectParent(res.result.parent);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const selector = async () => {
        try {
            setLoading(true);
            let res = await POST(SELECTAMP, { ampCode });
            if (res.success) {
                if (profile.role_id !== 1 && profile.role_id !== 2) {
                    setAmphur(res.result.ampur[0]);
                    setHname(res.result.hospital[0]);
                }
                setDataAmp(res.result.ampur);
                setDataHosp(res.result.hospital);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleAdd = async () => {
        setLoading(true); // ตั้งค่าให้เป็น true เมื่อเริ่มการอัปโหลด

        const formData = new FormData();
        formData.append("image_file_hr", fileUpload);
        formData.append("name", name);
        formData.append("position", position);
        formData.append("level", level);
        formData.append("salary", salary);
        formData.append("tel", tel);
        formData.append("address", address);
        if (parent) {
            formData.append("parent", parent);
        }
        try {
            const res = await axios.post(`${pathapi}/api/hr/insert_data_hr`, formData, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            // console.log("res:", res);
            if (res && res.data.success) {
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    text: `เพิ่มข้อมูลสำเร็จ`,
                    confirmButtonText: "ตกลง",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    text: res?.data.message || "Unknown error occurred",
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message || "Something went wrong.";
            Swal.fire({
                icon: "warning",
                text: errorMessage,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = async (f) => {
        console.log("f:", f);
        setLoading(true);

        const formData = new FormData();
        formData.append("old_img_file", f.basename);
        formData.append("name", f.name);
        formData.append("position", f.position);
        formData.append("level", f.level);
        formData.append("salary", f.salary);
        formData.append("tel", f.tel);
        formData.append("address", f.address);
        formData.append("staff_id", f.staffId);
        if (f.image_path) {
            formData.append("image_file_hr", f.image_path);
        }
        if (f.parent) {
            formData.append("parent", f.parent);
        }

        try {
            const res = await axios.post(`${pathapi}/api/hr/update_data_hr`, formData, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            // console.log("res:", res);
            if (res && res.data.success) {
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    text: `แก้ไขข้อมูลสำเร็จ`,
                    confirmButtonText: "ตกลง",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    text: res?.data.message || "Unknown error occurred",
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message || "Something went wrong.";
            Swal.fire({
                icon: "warning",
                text: errorMessage,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (row) => {
        try {
            setLoading(true);
            let res = await POST(DELETEHR, {
                staff_id: row.staff_id,
                old_img_file: row.basename,
            });
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    text: `ลบข้อมูลสำเร็จ`,
                    confirmButtonText: "ตกลง",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleClickOpenEdit = (data) => {
        setUserForm({
            name: data.name,
            position: data.position_id,
            address: data.address,
            level: data.level_id,
            salary: data.salary,
            tel: data.tel,
            parent: data.parent_id,
            staffId: data.staff_id,
            basename: data.basename,
            image_path: "",
            old_img_file: data.imgpath,
        });

        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleClickOpenAdd = () => {
        setOpenAdd(true);
    };

    const handleCloseAdd = () => {
        setOpenAdd(false);
    };

    const handleFileChange = (e) => {
        setFileUpload(e.target.files[0]);
    };

    useEffect(() => {
        getDataAll();
        selector();
    }, [ampCode, hcode, page, rowsPerPage]);

    useEffect(() => {
        getSelectHR();
    }, []);

    return (
        <>
            <HRTblCard
                handleClickOpenEdit={handleClickOpenEdit}
                data={data}
                header={header}
                handleDelete={handleDelete}
                handleClickOpenAdd={handleClickOpenAdd}
                handleCloseAdd={handleCloseAdd}
                page={page}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                profile={profile}
                dataAmp={dataAmp}
                dataHosp={dataHosp}
                setAmpCode={setAmpCode}
                setDataAmp={setDataAmp}
                amphur={amphur}
                setAmphur={setAmphur}
                setHcode={setHcode}
                setHname={setHname}
                hname={hname}
            />
            <DialogEdit
                open={openEdit}
                handleClose={handleCloseEdit}
                handleUpdate={handleEdit}
                name={name}
                setName={setName}
                position={position}
                setPosition={setPosition}
                salary={salary}
                setSalary={setSalary}
                address={address}
                setAddress={setAddress}
                tel={tel}
                setTel={setTel}
                level={level}
                setLevel={setLevel}
                parent={parent}
                setParent={setParent}
                selectHR={selectHR}
                selectLevel={selectLevel}
                selectParent={selectParent}
                data={userForm}
            />
            <DialogAdd
                handleClose={handleCloseAdd}
                handleAdd={handleAdd}
                handleFileChange={handleFileChange}
                open={openAdd}
                name={name}
                setName={setName}
                selectHR={selectHR}
                selectLevel={selectLevel}
                selectParent={selectParent}
                position={position}
                setPosition={setPosition}
                level={level}
                setLevel={setLevel}
                salary={salary}
                setSalary={setSalary}
                tel={tel}
                setTel={setTel}
                parent={parent}
                setParent={setParent}
                address={address}
                setAddress={setAddress}
                dataHR={data}
                isRoot={data.length > 0}
            />
        </>
    );
}

export default HRTable;
