import { Autocomplete, Box, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import HRFamilyTree from "./HRFamilyTree";
import { PersonIcon } from "../../../untils/icons";
import { getToken } from "../../../untils/shortcut";

function HRCard({ data, dataAmp, dataHosp, setAmpCode, setAmphur, amphur, setHcode, setHname, hname, hcode }) {
    // console.log("dataHosp:", dataHosp);

    const profile = getToken(localStorage.getItem("profile"));
    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={8} sm={6} md={6} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <PersonIcon fontSize="medium" />
                            <Typography className="main-header">ทรัพยากรบุคคล</Typography>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container columns={12} spacing={3} className="flex-end">
                    <Grid item xs={12} sm={12} md={3}>
                        <Autocomplete
                            disabled={profile.role_id !== 1 && profile.role_id !== 2}
                            fullWidth
                            getOptionLabel={(option) => (typeof option === "string" ? option : `${option.ampurname}`)}
                            options={dataAmp}
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            value={amphur}
                            noOptionsText="No Amphur"
                            onChange={(event, value) => {
                                if (!value) {
                                    setAmphur(null);
                                    setAmpCode(null);
                                    return;
                                }

                                if (value && value.ampurname) {
                                    setAmphur(value.ampurname);
                                    setAmpCode(value.ampurcode);
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="อำเภอ" />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Autocomplete
                            disabled={(profile.role_id !== 1 && profile.role_id !== 2) || !amphur}
                            fullWidth
                            getOptionLabel={(option) => (typeof option === "string" ? option : `${option.hcode} ${option.hname}`)}
                            options={dataHosp}
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            value={hname}
                            noOptionsText="No Hosp"
                            onChange={(event, value) => {
                                if (!value) {
                                    setHname(null);
                                    setHcode(null);
                                    return;
                                }

                                if (value && value.hcode) {
                                    setHname(value.hname);
                                    setHcode(value.hcode);
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="รพสต." />}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} mt={2} style={{ boxShadow: "10px 15px 15px 10px #E9E9E9", borderRadius: "10px" }}>
                    <HRFamilyTree dataTree={data} />
                </Grid>
            </Paper>
        </Box>
    );
}

export default HRCard;
