import { Autocomplete, Box, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { ButtonTeal } from "../../../Theme";
import { AddItemIcon, PersonIcon } from "../../../untils/icons";
import GridTable from "./GridTable";

function HRTblCard({
    handleClickOpenEdit,
    handleClickOpenAdd,
    data,
    header,
    handleDelete,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    profile,
    dataAmp,
    dataHosp,
    amphur,
    setAmphur,
    setAmpCode,
    hname,
    setHname,
    setHcode,
}) {
    // console.log("dataHR:", data);
    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12} spacing={2}>
                    <Grid item xs={8} sm={6} md={12} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <PersonIcon fontSize="medium" />
                            <Typography className="main-header">ข้อมูลทรัพยากรบุคคล</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Autocomplete
                            disabled={profile.role_id !== 1 && profile.role_id !== 2}
                            fullWidth
                            getOptionLabel={(option) => (typeof option === "string" ? option : `${option.ampurname}`)}
                            options={dataAmp}
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            value={amphur}
                            noOptionsText="No Amphur"
                            onChange={(event, value) => {
                                if (!value) {
                                    setAmphur(null);
                                    setAmpCode(null);
                                    return;
                                }

                                if (value && value.ampurname) {
                                    setAmphur(value.ampurname);
                                    setAmpCode(value.ampurcode);
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="อำเภอ" />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Autocomplete
                            disabled={(profile.role_id !== 1 && profile.role_id !== 2) || !amphur}
                            fullWidth
                            getOptionLabel={(option) => (typeof option === "string" ? option : `${option.hcode} ${option.hname}`)}
                            options={dataHosp}
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            value={hname}
                            noOptionsText="No Hosp"
                            onChange={(event, value) => {
                                if (!value) {
                                    setHname(null);
                                    setHcode(null);
                                    return;
                                }

                                if (value && value.hcode) {
                                    setHname(value.hname);
                                    setHcode(value.hcode);
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="รพสต." />}
                        />
                    </Grid>
                    {(profile.role_id === 1 || profile.role_id === 2) && (
                        <Grid item xs={12} sm={12} md={5} className="flex-end">
                            <Stack direction="row" spacing={1}>
                                <ButtonTeal onClick={handleClickOpenAdd}>
                                    <AddItemIcon fontSize="small" />
                                    &nbsp;
                                    <Typography sx={{ display: { xs: "none", sm: "block" }, cursor: "pointer" }}>เพิ่มข้อมูล</Typography>
                                </ButtonTeal>
                            </Stack>
                        </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={12} my={2}>
                        <GridTable
                            handleClickOpenEdit={handleClickOpenEdit}
                            data={data}
                            header={header}
                            handleDelete={handleDelete}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            setPage={setPage}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

export default HRTblCard;
