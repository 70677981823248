import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box, Grid, IconButton, Skeleton, Typography } from "@mui/material";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export default function BarChartVertical({ information }) {
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        if (information && information.length > 0) {
            setLoading(false);
        }
    }, [information]);

    const options = {
        indexAxis: "x",
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            datalabels: false,
        },
        legend: {
            display: false,
        },
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = information.slice(indexOfFirstItem, indexOfLastItem);

    const nextPage = () => {
        if (currentPage * itemsPerPage < information.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // const labels = ["ต.ค.", "พ.ย.", "ธ.ค.", "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย."];
    const modifyHospitalName = (name) => {
        return name.replace("โรงพยาบาลส่งเสริมสุขภาพตำบล", "").trim();
    };

    const labels = currentItems.map((e) => modifyHospitalName(e.name));

    const data = {
        labels: labels,
        datasets: [
            {
                label: "จำนวนเงิน",
                data: currentItems.map((e) => e.value),
                // data: [10000000, 10000000, 10000, 1000, 10000000, 10000001, 100],
                borderColor: "rgb(0, 140, 238)",
                backgroundColor: "rgba(0, 140, 238, 0.5)",
            },
        ],
    };

    return (
        // <Grid container columns={12}>
        //      <Grid item xs={12} className="census-info-chart-h">
        //           {/* {information.length > 0 ?
        //           // <Bar data={data} options={options} />
        //           <Bar data={data} options={options} />
        //           :
        //           <Box className="census-info-empty">
        //               <Typography>
        //                   * ไม่มีข้อมูล *
        //               </Typography>
        //           </Box>
        //       } */}
        //           <Bar data={data} options={options} />
        //      </Grid>
        // </Grid>

        <Grid container columns={12}>
            <Grid item xs={12} className="flex-center">
                {loading ? (
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                        <Skeleton variant="rectangular" animation="wave" width="100%" height="100%" />
                    </Box>
                ) : currentItems.length > 0 ? (
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <IconButton onClick={prevPage} disabled={currentPage === 1}>
                            <ArrowBackIos />
                        </IconButton>
                        <Box sx={{ width: "100%", height: "500px", display: "flex", justifyContent: "center" }}>
                            <Bar data={data} options={options} />
                        </Box>
                        <IconButton onClick={nextPage} disabled={currentPage * itemsPerPage >= information.length}>
                            <ArrowForwardIos />
                        </IconButton>
                    </Box>
                ) : information.length > 0 ? (
                    <Bar data={data} options={options} style={{ overflowX: "auto", overflowY: "auto", maxHeight: "500px", width: "100%" }} />
                ) : (
                    <Box className="census-info-empty">
                        <Typography>* ไม่มีข้อมูล *</Typography>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
}
