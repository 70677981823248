import { Container, debounce } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import { POST_GIS, REPORTDIAGNOSE, REPORTDIAGNOSEMAP } from "../../service";
import { getToken } from "../../untils/shortcut";
import ReportCard from "./ReportDiagnose/ReportCard";
import { select_data } from "../../untils/static";

function ReportDiagnose() {
    const [data, setData] = useState([]);
    const [ageData, setAgeData] = useState([]);
    const [topData, setTopData] = useState([]);
    const [yearData, setYearData] = useState([]);
    const [sexData, setSexData] = useState([]);
    const [dataLocation, setDataLocation] = useState([]);
    const [loading, setLoading] = useState(false);
    const [subdistrictObj, setSubdistrictObj] = useState([]);
    const [selectHname, setSelectHname] = useState("ทั้งหมด");
    const [dateStart, setDateStart] = useState(dayjs(new Date()).startOf("year").utc(true));
    const [dateEnd, setDateEnd] = useState(dayjs(new Date()));
    const [searchLoca, setSearchLoca] = useState("");
    const [inputLoca, setInputLoca] = useState("");
    const [hname, setHName] = useState("");
    const profile = getToken(localStorage.getItem("profile"));
    const [sex, setSex] = useState("ทั้งหมด");

    const getReportDiagnose = async () => {
        try {
            setLoading(true);
            let res = await POST_GIS(REPORTDIAGNOSE, { hname, dateStart, dateEnd, inputSex: sex });
            // console.log(res);
            if (res.success) {
                setData(res.result);
                setAgeData(res.result.ageDiagData);
                setTopData(res.result.diagDisease);
                setYearData(res.result.diagPerYear);
                setSexData(res.result.sexCountType);
                setDataLocation(res.result.forSelect);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const getReportDiagnoseMap = async () => {
        setSubdistrictObj([]);
        try {
            setLoading(true);
            let res = await POST_GIS(REPORTDIAGNOSEMAP, { hname, dateStart, dateEnd, inputSex: sex });
            // console.log(res.result);
            if (res.success) {
                setSubdistrictObj(res.result.features);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };
    //Check role_id
    const fetchLocation = useMemo(
        () =>
            debounce(async (request) => {
                try {
                    let res = await POST_GIS(REPORTDIAGNOSE, { search: request });
                    if (res.success) {
                        if (profile.role_id !== 1 && profile.role_id !== 2) {
                            setSearchLoca(res.result.forSelect[0]);
                        }
                        setDataLocation(res.result.forSelect);
                    } else {
                        Swal.fire({
                            icon: "warning",
                            text: `ค้นหาไม่สำเร็จ`,
                            confirmButtonText: "ตกลง",
                        });
                    }
                } catch (error) {
                    Swal.fire({
                        icon: "warning",
                        text: `${error}`,
                        confirmButtonText: "ตกลง",
                    });
                }
            }, 400),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        if (!(profile.role_id !== 1 && profile.role_id !== 2)) {
            if (inputLoca.length > 3) {
                fetchLocation(inputLoca);
            } else if (!inputLoca) {
                fetchLocation(inputLoca);
                return undefined;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchLoca, inputLoca, fetchLocation]);

    useEffect(() => {
        if (profile.role_id !== 1 && profile.role_id !== 2) {
            setInputLoca(`${profile.location_code} ${profile.location_name}`);
            fetchLocation(profile.location_code);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getReportDiagnose();
        getReportDiagnoseMap();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hname, dateStart, dateEnd, sex]);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <ReportCard
                data={data}
                ageData={ageData}
                topData={topData}
                yearData={yearData}
                sexData={sexData}
                dataLocation={dataLocation}
                subdistrictObj={subdistrictObj}
                date_start={dateStart}
                date_end={dateEnd}
                setDateStart={(e) => setDateStart(e)}
                setDateEnd={(e) => setDateEnd(e)}
                setSelectHname={setSelectHname}
                selectHname={selectHname}
                profile={profile}
                searchLoca={searchLoca}
                setSearchLoca={setSearchLoca}
                setInputLoca={setInputLoca}
                setHName={setHName}
                hname={hname}
                sex={sex}
                setSex={setSex}
                sextype={select_data.sex}
            />
        </Container>
    );
}

export default ReportDiagnose;
