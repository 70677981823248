import { Button, Grid, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import React from "react";
import { StyledTableCell } from "../../../Theme";
import { formatPhone, getToken, tofieds } from "../../../untils/shortcut";

export default function GridTable({ data, handleClickOpenEdit, header, handleDelete, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage }) {
    const profile = localStorage.getItem("profile");
    const profile_id = getToken(localStorage.getItem("profile"));
    const token = localStorage.getItem("uuid");
    const pathapi = process.env.REACT_APP_SERVICE;

    return (
        <Paper sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: 800, overflowX: "auto" }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        {header.map((item) => {
                            return (
                                <>
                                    <StyledTableCell align="center">{item}</StyledTableCell>
                                </>
                            );
                        })}
                        <StyledTableCell align="center">ดำเนินการ</StyledTableCell>
                    </TableHead>
                    <TableBody>
                        {profile_id.role_id !== 1 && profile_id.role_id !== 2
                            ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                  return (
                                      <TableRow key={index + 1}>
                                          <StyledTableCell align="left">
                                              <img
                                                  src={`${pathapi}${row.imgpath}?secret_key=${token}&data=${profile}`}
                                                  alt="person"
                                                  style={{
                                                      width: "100px",
                                                      height: "120px",
                                                      objectFit: "cover",
                                                  }}
                                              ></img>
                                          </StyledTableCell>
                                          <StyledTableCell align="left" style={{ minWidth: 250 }}>
                                              {row.hosp}
                                          </StyledTableCell>
                                          <StyledTableCell align="left" style={{ minWidth: 200 }}>
                                              {row.name}
                                          </StyledTableCell>
                                          <StyledTableCell align="center" style={{ minWidth: 200 }}>
                                              {row.position}
                                          </StyledTableCell>
                                          <StyledTableCell align="center" style={{ minWidth: 150 }}>
                                              {row.level}
                                          </StyledTableCell>
                                          {/* <StyledTableCell align="left" style={{ minWidth: 150 }}>
                                              {tofieds(row.salary)}
                                          </StyledTableCell> */}
                                          <StyledTableCell align="left" style={{ minWidth: 300 }}>
                                              {row.address}
                                          </StyledTableCell>
                                          <StyledTableCell align="right" style={{ minWidth: 150 }}>
                                              {formatPhone(row.tel)}
                                          </StyledTableCell>
                                          <StyledTableCell align="left" style={{ minWidth: 200 }}>
                                              {row.parent}
                                          </StyledTableCell>
                                          <StyledTableCell align="center" style={{ minWidth: 200 }}>
                                              <Button variant="text" onClick={() => handleClickOpenEdit(row)}>
                                                  แก้ไข
                                              </Button>
                                              <Button variant="text" color="error" onClick={() => handleDelete(row)}>
                                                  ลบ
                                              </Button>
                                          </StyledTableCell>
                                      </TableRow>
                                  );
                              })
                            : data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                  return (
                                      <TableRow key={index + 1}>
                                          <StyledTableCell align="left">
                                              <img
                                                  src={`${pathapi}${row.imgpath}?secret_key=${token}&data=${profile}`}
                                                  alt="person"
                                                  style={{
                                                      width: "100px",
                                                      height: "120px",
                                                      objectFit: "cover",
                                                  }}
                                              ></img>
                                          </StyledTableCell>
                                          <StyledTableCell align="left" style={{ minWidth: 250 }}>
                                              {row.hosp}
                                          </StyledTableCell>
                                          <StyledTableCell align="left" style={{ minWidth: 200 }}>
                                              {row.name}
                                          </StyledTableCell>
                                          <StyledTableCell align="center" style={{ minWidth: 200 }}>
                                              {row.position}
                                          </StyledTableCell>
                                          <StyledTableCell align="center" style={{ minWidth: 150 }}>
                                              {row.level}
                                          </StyledTableCell>
                                          <StyledTableCell align="right" style={{ minWidth: 150 }}>
                                              {tofieds(row.salary)}
                                          </StyledTableCell>
                                          <StyledTableCell align="left" style={{ minWidth: 300 }}>
                                              {row.address}
                                          </StyledTableCell>
                                          <StyledTableCell align="right" style={{ minWidth: 150 }}>
                                              {formatPhone(row.tel)}
                                          </StyledTableCell>
                                          <StyledTableCell align="left" style={{ minWidth: 200 }}>
                                              {row.parent}
                                          </StyledTableCell>
                                          <StyledTableCell align="center" style={{ minWidth: 200 }}>
                                              <Button variant="text" onClick={() => handleClickOpenEdit(row)}>
                                                  แก้ไข
                                              </Button>
                                              <Button variant="text" color="error" onClick={() => handleDelete(row)}>
                                                  ลบ
                                              </Button>
                                          </StyledTableCell>
                                      </TableRow>
                                  );
                              })}
                    </TableBody>
                </Table>
            </TableContainer>
            {data.length === 0 ? (
                <Grid container justifyContent="center" my={3}>
                    <Typography noWrap>* ไม่มีข้อมูลในตาราง *</Typography>
                </Grid>
            ) : (
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    // count={count}
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                />
            )}
        </Paper>
    );
}
