import { Handle, Position } from "@xyflow/react";
import React, { memo } from "react";

export default memo(({ data, isConnectable }) => {
    // console.log("isConnectable:", isConnectable);
    return (
        <>
            <Handle
                type="target"
                position={Position.Top}
                onConnect={(params) => console.log("handle onConnect", params)}
                isConnectable={isConnectable}
            />
            <div style={{ textAlign: "center", padding: "10px", borderRadius: "10px", border: "2px solid #ccc" }}>
                <img src={data.image_src} alt={data.label} style={{ width: "100px", height: "100px", borderRadius: "50%" }} />
                <div style={{ textAlign: "left", fontSize: "16px" }}>
                    <strong>ชื่อ :</strong>
                    {data.label}
                </div>
                <div style={{ textAlign: "left", fontSize: "14px" }}>
                    <strong>ตำแหน่ง :</strong>
                    {data.position}
                </div>
            </div>
            <Handle type="source" position={Position.Bottom} id="a" isConnectable={isConnectable} />
        </>
    );
});
