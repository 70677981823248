import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { GET, GETDATAHR, POST, SELECTAMP, SELECTORHR } from "../../service";
import { getToken } from "../../untils/shortcut";
import HRCard from "./components/HRCard";

function HumanResources() {
    const [loading, setLoading] = useState(false);
    const [selectHR, setSelectHR] = useState();
    const [selectLevel, setSelectLevel] = useState();
    const [selectParent, setSelectParent] = useState();
    const [dataHR, setDataHR] = useState([]);
    const [amphur, setAmphur] = useState("");
    const [ampCode, setAmpCode] = useState("");
    const [dataAmp, setDataAmp] = useState([]);
    const [hcode, setHcode] = useState("");
    const [hname, setHname] = useState("");
    const [dataHosp, setDataHosp] = useState([]);

    const profile = getToken(localStorage.getItem("profile"));

    const getDataHR = async () => {
        try {
            setLoading(true);
            let res = await POST(GETDATAHR, {
                ampCode,
                hcode,
            });
            if (res.success) {
                setDataHR(res.result.treeDiagram);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const getSelectHR = async () => {
        try {
            setLoading(true);
            let res = await GET(SELECTORHR);
            if (res.success) {
                setSelectHR(res.result.position);
                setSelectLevel(res.result.level);
                setSelectParent(res.result.parent);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const selector = async () => {
        try {
            setLoading(true);
            let res = await POST(SELECTAMP, { ampCode });
            if (res.success) {
                if (profile.role_id !== 1 && profile.role_id !== 2) {
                    setAmphur(res.result.ampur[0]);
                    setHname(res.result.hospital[0]);
                }
                setDataAmp(res.result.ampur);
                setDataHosp(res.result.hospital);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getSelectHR();
        getDataHR();
        selector();
    }, [ampCode, hcode]);

    return (
        <>
            <HRCard
                data={dataHR}
                dataAmp={dataAmp}
                dataHosp={dataHosp}
                setAmpCode={setAmpCode}
                setDataAmp={setDataAmp}
                amphur={amphur}
                setAmphur={setAmphur}
                setHcode={setHcode}
                setHname={setHname}
                hname={hname}
            />
        </>
    );
}

export default HumanResources;
