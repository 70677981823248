import React, { useEffect, useState } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import { Box, Grid, Typography, Button, Skeleton, IconButton } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function BarChartDashboard({ death }) {
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const nextPage = () => {
        if (currentPage * itemsPerPage < death.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    useEffect(() => {
        if (death && death.length > 0) {
            setLoading(false);
        }
    }, [death]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = death.slice(indexOfFirstItem, indexOfLastItem);

    const options = {
        indexAxis: "y",
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            datalabels: false,
            legend: {
                display: false,
            },
            tooltip: {
                intersect: false,
                callbacks: {
                    label: function (context) {
                        var label = context.dataset.label || "";
                        var value = context.formattedValue;
                        return `${label} : ${value} `;
                    },
                },
            },
        },
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                ticks: {
                    callback: function (value) {
                        const label = this.getLabelForValue(value);
                        return label.length > 20 ? label.substr(0, 20).trim() + "..." : label;
                    },
                },
            },
        },
    };

    const labels = currentItems.map((e) => e.cdeath_a_th);

    const data = {
        labels,
        datasets: [
            {
                label: "จำนวนครั้ง",
                data: currentItems.map((e) => e.death),
                borderColor: "rgb(54, 162, 235)",
                backgroundColor: "rgba(54, 162, 235, 0.5)",
            },
        ],
    };

    return (
        <Grid container columns={12}>
            <Grid item xs={12} className="flex-center">
                {loading ? (
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                        <Skeleton variant="rectangular" animation="wave" width="100%" height="100%" />
                    </Box>
                ) : currentItems.length > 0 ? (
                    <Box sx={{ display: "relative", alignItems: "center", justifyContent: "center", width: "100%", flexDirection: "row" }}>
                        <Box sx={{ minHeight: "500px", width: "100%", height: "100%", display: "flex", justifyContent: "center" }}>
                            <Bar data={data} options={options} />
                        </Box>
                        <Box className="flex-start" justifyContent="space-between">
                            <IconButton onClick={prevPage} disabled={currentPage === 1}>
                                <ArrowBackIos />
                            </IconButton>
                            <IconButton onClick={nextPage} disabled={currentPage * itemsPerPage >= death.length}>
                                <ArrowForwardIos />
                            </IconButton>
                        </Box>
                    </Box>
                ) : death.length > 0 ? (
                    <Bar data={data} options={options} style={{ overflowX: "auto", overflowY: "auto", maxHeight: "500px", width: "100%" }} />
                ) : (
                    <Box className="census-info-empty">
                        <Typography>* ไม่มีข้อมูล *</Typography>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
}
