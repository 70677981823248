import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import BackdropLoad from "../../../components/Backdrop/BackdropLoad.js";
// import { getToken } from "../../../untils/shortcut.js";
import SsoCard from "./components/SsoCard.js";
import { headerTable } from "../../../untils/static.js";
import { GETDATASSO, POST } from "../../../service.js";
import Swal from "sweetalert2";
import { getToken } from "../../../untils/shortcut.js";

function Sso() {
    // const profile = getToken(localStorage.getItem("profile"));

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [dateStart, setDateStart] = useState(dayjs(new Date()).startOf("month"));
    const [dateEnd, setDateEnd] = useState(dayjs(new Date()));
    const [status, setStatus] = useState(99);
    const [search, setSearch] = useState("");
    const [type, setType] = useState(99);
    const [statusSend, setStatusSend] = useState(99);
    const profile = getToken(localStorage.getItem("profile"));
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getDataSso = async () => {
        try {
            setLoading(true);
            let res = await POST(GETDATASSO, { date_start: dateStart, date_end: dateEnd, text: search, status_c: status });
            if (res.success) {
                // console.log(res.result);
                let data = [];
                let data_claim = res.result;

                data_claim.map((e, i) =>
                    data.push({
                        ...e,
                        check: false,
                        status: 0,
                        index: i,
                    })
                );
                setPage(0);
                setData(data);
                setLoading(false);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const handleSendClaim = (item) => {
        // console.log("click");
        let res_claim = data.filter((e) => e.check === true);
        let data_send_claim = [];
        res_claim.map((e) =>
            data_send_claim.push({
                ...e,
                // age: e.age,
                // claimAmt: e.claimAmt,
                // date: e.date,
                // hn: e.hn,
                // invno: e.invno,
                // name: e.name,
            })
        );

        if (data_send_claim.length > 0) {
            if (statusSend > 0) {
                Swal.fire({
                    title: "ส่งออกข้อมูล",
                    text: "ตรวจสอบรายการในเว็บ SSOP ก่อนจะส่งออกข้อมูลซ้ำ",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "ยืนยัน",
                    confirmButtonColor: "green",
                    cancelButtonText: "ยกเลิก",
                    reverseButtons: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        SendClaim(data_send_claim);
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire({
                            text: "ยกเลิกรายการแล้ว",
                            confirmButtonText: "ตกลง",
                        });
                    }
                });
            } else {
                const filtered = res_claim.filter((obj) => obj.PERMITNO === "" || obj.pre_audit.length > 0);
                if (filtered.length > 0) {
                    Swal.fire({
                        title: "ส่งออกข้อมูล",
                        text: "คุณต้องการส่งออกข้อมูลที่ไม่ผ่านการ Pre Audit หรือไม่?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "ยืนยัน",
                        confirmButtonColor: "green",
                        cancelButtonText: "ยกเลิก",
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            SendClaim(data_send_claim);
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                            Swal.fire({
                                text: "ยกเลิกรายการแล้ว",
                                confirmButtonText: "ตกลง",
                            });
                        }
                    });
                } else {
                    Swal.fire({
                        title: "ส่งออกข้อมูล",
                        text: "คุณต้องการส่งออกข้อมูลหรือไม่?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "ยืนยัน",
                        confirmButtonColor: "green",
                        cancelButtonText: "ยกเลิก",
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            SendClaim(data_send_claim);
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                            Swal.fire({
                                text: "ยกเลิกรายการแล้ว",
                                confirmButtonText: "ตกลง",
                            });
                        }
                    });
                }
            }
        } else {
            Swal.fire({
                icon: "warning",
                title: `ไม่มีข้อมูลส่งออก`,
                text: "กรุณาเลือกข้อมูลส่งออกอย่างน้อย 1 รายการ",
                confirmButtonText: "ตกลง",
            });
        }
    };

    //SENDCLAIM1 และ SENDCLAIM2 รอหลังบ้านทำ path มาให้
    const SendClaim = async (obj) => {
        setLoading(true);
        if (profile.option_claim === null) {
            Swal.fire({
                icon: "warning",
                text: `กรุณาเลือกรูปแบบการเคลมก่อนส่งเคลม`,
                confirmButtonText: "ตกลง",
            });
        } else {
            try {
                let res = await POST("/claim/claim_sso", {
                    data: obj,
                });
                if (res.success) {
                    setLoading(false);
                    Swal.fire({
                        icon: "success",
                        text: `ส่งออกข้อมูลสำเร็จ`,
                        confirmButtonText: "ตกลง",
                    }).then((result) => {
                        // if (result.isConfirmed) {
                        // window.location.reload();
                        getDataSso();

                        // }
                    });
                    sendFileName(res.result.file_name, res.result.ssop_claim);
                } else {
                    setLoading(false);
                    Swal.fire({
                        icon: "warning",
                        text: `${res.message}`,
                        showConfirmButton: true,
                    });
                }
            } catch (error) {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${error}`,
                    confirmButtonText: "ตกลง",
                });
            }
        }
    };

    const sendFileName = async (file_name, ssop_claim) => {
        try {
            await POST("/token/upload_sso", { file_name, ssop_claim });
        } catch (error) {
            console.log("AL: error", error);
        }
    };

    const handleChangeCheckbox = (event) => {
        let id = event.target.value;

        if (event.target.name === "ทั้งหมด") {
            // let data = []
            data.map((e) =>
                setData((prevData) =>
                    prevData.map((item) =>
                        Number(item.index) === Number(e.index)
                            ? { ...item, check: event.target.checked, status: event.target.checked === true ? 1 : 0 }
                            : item
                    )
                )
            );
        } else {
            setData((prevData) =>
                prevData.map((item) =>
                    Number(item.index) === Number(id) ? { ...item, check: event.target.checked, status: event.target.checked === true ? 1 : 0 } : item
                )
            );
        }
    };

    useEffect(() => {
        getDataSso();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateStart, dateEnd, status, search]);

    return (
        <>
            <BackdropLoad loading={loading} />
            <SsoCard
                handleSendClaim={handleSendClaim}
                header={headerTable.claim_sso}
                data={data}
                handleChangeCheckbox={handleChangeCheckbox}
                checkAll={data.findIndex((x) => x.status === 0)}
                dateStart={dateStart}
                dateEnd={dateEnd}
                setDateStart={(e) => setDateStart(e)}
                setDateEnd={(e) => setDateEnd(e)}
                setStatus={setStatus}
                status={status}
                search={search}
                setSearch={setSearch}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </>
    );
}

export default Sso;
