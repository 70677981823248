import { Container, debounce } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import { GETMAPREPORTDEATH, POST_GIS, REPORTDEATH } from "../../service";
import { getToken } from "../../untils/shortcut";
import ReportCard from "./ReportDeath/ReportCard";
import { select_data } from "../../untils/static";

function ReportDeath() {
    const profile = getToken(localStorage.getItem("profile"));
    const [subdistrictObj, setSubdistrictObj] = useState([]);
    const [death, setDeath] = useState([]);
    const [pDeath, setPDeath] = useState([]);
    const [yDeath, setYDeath] = useState([]);
    const [dataLocation, setDataLocation] = useState([]);
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState("");
    const [hname, setHName] = useState("");
    const [dateStart, setDateStart] = useState(dayjs().startOf("year").utc(true));
    const [dateEnd, setDateEnd] = useState(dayjs());
    const [searchLoca, setSearchLoca] = useState("");
    const [inputLoca, setInputLoca] = useState("");
    const [sex, setSex] = useState("ทั้งหมด");

    const getReportDeath = async () => {
        try {
            setLoading(true);
            let res = await POST_GIS(REPORTDEATH, { hname, location, dateStart, dateEnd, inputSex: sex });
            // console.log('death',res.result);
            if (res.success) {
                setDeath(res.result.cDeathData);
                setPDeath(res.result.pDeathData);
                setYDeath(res.result.dDeathTY);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const getMapReportDeath = async () => {
        setSubdistrictObj([]);
        try {
            setLoading(true);
            let res = await POST_GIS(GETMAPREPORTDEATH, { hname, location, dateStart, dateEnd, inputSex: sex });
            if (res.success) {
                setSubdistrictObj(res.result.features);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const fetchLocation = useMemo(
        () =>
            debounce(async (request) => {
                try {
                    let res = await POST_GIS(REPORTDEATH, { search: request });
                    if (res.success) {
                        if (profile.role_id !== 1 && profile.role_id !== 2) {
                            setSearchLoca(res.result.forSelect[0].hname);
                        }
                        setDataLocation(res.result.forSelect);
                    } else {
                        Swal.fire({
                            icon: "warning",
                            text: `ค้นหาไม่สำเร็จ`,
                            confirmButtonText: "ตกลง",
                        });
                    }
                } catch (error) {
                    Swal.fire({
                        icon: "warning",
                        text: `${error}`,
                        confirmButtonText: "ตกลง",
                    });
                }
            }, 400),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        if (!(profile.role_id !== 1 && profile.role_id !== 2)) {
            if (inputLoca.length > 3) {
                fetchLocation(inputLoca);
            } else if (!inputLoca) {
                fetchLocation(inputLoca);
                return undefined;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchLoca, inputLoca, fetchLocation]);

    useEffect(() => {
        if (profile.role_id !== 1 && profile.role_id !== 2) {
            setInputLoca(`${profile.location_code} ${profile.location_name}`);
            fetchLocation(profile.location_code);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getReportDeath();
        getMapReportDeath();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hname, location, dateStart, dateEnd, sex]);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <ReportCard
                profile={profile}
                pDeath={pDeath}
                death={death}
                yDeath={yDeath}
                subdistrictObj={subdistrictObj}
                location={location}
                setLocation={setLocation}
                setHName={setHName}
                dataLocation={dataLocation}
                inputLoca={inputLoca}
                setInputLoca={setInputLoca}
                searchLoca={searchLoca}
                setSearchLoca={setSearchLoca}
                date_start={dateStart}
                date_end={dateEnd}
                setDateStart={(e) => setDateStart(e)}
                setDateEnd={(e) => setDateEnd(e)}
                sex={sex}
                setSex={setSex}
                sextype={select_data.sex}
            />
        </Container>
    );
}

export default ReportDeath;
