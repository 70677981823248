import { Box, Button, Grid, Paper, Stack, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import { StyledTableCell } from "../../../Theme";
import { tofieds } from "../../../untils/shortcut";
import dayjs from "dayjs";

export default function FinancialTable({ header, data, handleClickOpenEdit, handleDelete }) {
    // console.log("data:", data);
    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {header.map((column) => (
                                <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => {
                            return (
                                <TableRow key={index + 1}>
                                    <StyledTableCell align="center">{dayjs(row.finance_date).format("DD MMM BBBB")}</StyledTableCell>
                                    <StyledTableCell align="center">{row.ref_doc}</StyledTableCell>
                                    <StyledTableCell align="center">{row.account_no}</StyledTableCell>
                                    <StyledTableCell align="center">{row.account_name}</StyledTableCell>
                                    <StyledTableCell align="right">{tofieds(row.debit)}</StyledTableCell>
                                    <StyledTableCell align="right">{tofieds(row.credit)}</StyledTableCell>
                                    <StyledTableCell align="center">{row.detail}</StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Box display={"flex"} justifyContent="center" gap={1} alignItems={"center"}>
                                            <Button variant="contained" color="success" onClick={() => handleClickOpenEdit(row.id)}>
                                                ยืนยัน
                                            </Button>
                                            <Button variant="contained" color="error" onClick={() => handleDelete(row.id)}>
                                                ยกเลิก
                                            </Button>
                                        </Box>
                                    </StyledTableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}
