import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, CircularProgress, Skeleton } from "@mui/material";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import { tofieds } from "../../../untils/shortcut";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export default function ChartOPDClaim({ perMonthAll, dataSent }) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (perMonthAll.length > 0 && dataSent.length > 0) {
            setLoading(false);
        }
    }, [perMonthAll, dataSent]);

    const labels = perMonthAll.map((e) => e.month);

    const options = {
        indexAxis: "x",
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            interaction: {
                intersect: false,
                mode: "index",
            },
            datalabels: {
                display: function (context) {
                    return context.chart.isDatasetVisible(context.datasetIndex);
                },
                formatter: function (value) {
                    return `${value > 0 ? tofieds(value) : value}`;
                },
                borderRadius: 4,
                align: "end",
                anchor: "end",
                color: "black",
            },
            tooltip: {
                mode: "index",
                intersect: false,
                callbacks: {
                    label: function (context) {
                        var label = context.dataset.label || "";
                        var value = context.formattedValue;
                        return `${label} : ${value > 0 ? tofieds(value) : value}`;
                    },
                },
            },
        },
        legend: {
            display: false,
        },
    };

    const data = {
        labels,
        datasets: [
            {
                label: "จำนวน OPD",
                data: perMonthAll.map((e) => e.count),
                borderColor: "rgb(23, 144, 255)",
                backgroundColor: "rgba(23, 144, 255, 0.5)",
            },
            {
                label: "ส่งแล้ว",
                data: dataSent.map((e) => e.count),
                borderColor: "rgb(14, 32, 142)",
                backgroundColor: "rgba(14, 32, 142, 0.5)",
            },
        ],
    };

    return (
        <Grid container columns={12}>
            <Grid item xs={12} className="census-info-chart-h">
                {loading ? (
                    <Grid item xs={12}>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Skeleton variant="rectangular" animation="wave" width="100%" height="400px" />
                        </Box>
                    </Grid>
                ) : perMonthAll.length > 0 && dataSent.length > 0 ? (
                    <Bar data={data} options={options} />
                ) : (
                    <Box className="census-info-empty">
                        <Typography>* ไม่มีข้อมูล *</Typography>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
}
