import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { ButtonTeal } from "../../../Theme";
import { CloseIcn } from "../../../untils/icons";

export default function DialogEdit({ open, handleClose, handleUpdate, urlId, selectHR, selectLevel, selectParent, data }) {
    const pathapi = process.env.REACT_APP_SERVICE;
    const [dataUser, setDataUser] = useState(data);
    const formRef = useRef();

    const checkValidation = (id) => {
        if (formRef.current.reportValidity()) {
            handleClose();
            handleUpdate(dataUser);
        }
    };

    const onChange = (e) => {
        setDataUser((p) => ({ ...p, [e.target.name]: e.target.value }));
    };

    const handleFileChange = (e) => {
        setDataUser((p) => ({ ...p, [e.target.name]: e.target.files[0] }));
    };

    useEffect(() => {
        setDataUser(data);
    }, [data]);

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose} className="bg-dialog">
            <Box className="bg-gradiant">
                <DialogActions className="flex-end">
                    <CloseIcn className="close-icn" onClick={handleClose} />
                </DialogActions>
                <DialogTitle className="text-header">แก้ไขข้อมูล</DialogTitle>
                <DialogContent className="flex-center">
                    <form ref={formRef} className="flex-center">
                        <DialogContentText mt={2} className="container-bg" component={"span"}>
                            <Grid container columns={12} spacing={2}>
                                {dataUser.old_img_file ? (
                                    <Grid item xs={12}>
                                        <img
                                            src={`${pathapi}${dataUser.old_img_file}`}
                                            alt="person"
                                            style={{
                                                width: "100px",
                                                height: "150px",
                                                objectFit: "fill",
                                            }}
                                        />
                                        <Button
                                            onClick={() => {
                                                setDataUser((p) => ({ ...p, old_img_file: "" }));
                                            }}
                                        >
                                            ลบรูปภาพ
                                        </Button>
                                    </Grid>
                                ) : (
                                    <Grid item xs={12}>
                                        <input name="image_path" type="file" onChange={handleFileChange} />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="ชื่อ"
                                        name="name"
                                        variant="outlined"
                                        value={dataUser.name}
                                        onChange={(e) => onChange(e)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth={true} required>
                                        <InputLabel>ตำแหน่ง</InputLabel>
                                        <Select name="position" value={dataUser.position} label="เลือกตำแหน่ง" onChange={(e) => onChange(e)}>
                                            {Array.isArray(selectHR) &&
                                                selectHR.map((item, index) => (
                                                    <MenuItem key={index} value={item.position_id}>
                                                        {item.position_name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth={true} required>
                                        <InputLabel>ระดับ</InputLabel>
                                        <Select name="level" value={dataUser.level} label="เลือกระดับ" required onChange={(e) => onChange(e)}>
                                            {Array.isArray(selectLevel) &&
                                                selectLevel.map((item, index) => (
                                                    <MenuItem key={index} value={item.level_id}>
                                                        {item.level_name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="รายได้"
                                        name="salary"
                                        variant="outlined"
                                        value={dataUser.salary}
                                        onChange={(e) => onChange(e)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="เบอร์โทรศัพท์"
                                        name="tel"
                                        variant="outlined"
                                        value={dataUser.tel}
                                        onChange={(e) => onChange(e)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth={true}>
                                        <InputLabel>หัวหน้า</InputLabel>
                                        <Select name="parent" value={dataUser.parent} label="เลือกหัวหน้า" onChange={(e) => onChange(e)}>
                                            {Array.isArray(selectParent) &&
                                                selectParent.map((item, index) => (
                                                    <MenuItem key={index} value={item.staff_id}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="ที่อยู่"
                                        name="address"
                                        variant="outlined"
                                        value={dataUser.address}
                                        onChange={(e) => onChange(e)}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </form>
                </DialogContent>

                <DialogActions className="flex-center">
                    <ButtonTeal
                        sx={{ width: { xs: "200px", sm: "50%", md: "30%" } }}
                        onClick={() => {
                            checkValidation(urlId, dataUser);
                        }}
                    >
                        แก้ไขข้อมูล
                    </ButtonTeal>
                </DialogActions>
            </Box>
        </Dialog>
    );
}
