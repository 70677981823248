import React, { useEffect, useState } from "react";

import { Container } from "@mui/material";

import dayjs from "dayjs";
import Swal from "sweetalert2";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import { DASHBOARDK103, DASHBOARDSELECTDATA, POST_GIS } from "../../service";
import { getToken } from "../../untils/shortcut";
import K103Card from "./K103/K103Card";

function K103() {
    const profile = getToken(localStorage.getItem("profile"));
    const year_length = new Date().getFullYear() - 2024;
    const yearsData = Array.from({ length: year_length + 2 }, (_, index) => {
        return { value: 2024 + index };
    });
    const [data, setData] = useState([]);
    const [dataByQ, setDataByQ] = useState([]);
    const [headerByQ, setHeaderByQ] = useState({});
    const [loading, setLoading] = useState(false);
    const [year, setYear] = useState(dayjs().year());
    const [quarter, setQuarter] = useState("");
    const [location, setLocation] = useState("");
    const [dataAmphur, setDataAmphur] = useState([]);
    const [dataTambon, setDataTambon] = useState([]);
    const [dataLocation, setDataLocation] = useState([]);
    const [searchData, setSearchData] = useState({ amphur: "", tambon: "", hname: "", quarter: "", year: yearsData[0].value });

    const onSelect = async (name, value) => {
        // console.log("value:", value);
        // console.log("name:", name);
        let searchDataTemp = { ...searchData };
        if (name === "amphur") {
            searchDataTemp = { ...searchDataTemp, [name]: value, tambon: "", hname: "" };
        } else if (name === "tambon") {
            searchDataTemp = { ...searchDataTemp, [name]: value, hname: "" };
        } else if (name === "quarter") {
            searchDataTemp = { ...searchDataTemp, [name]: value, amphur: "", tambon: "", hname: "" };
        } else {
            searchDataTemp = { ...searchDataTemp, [name]: value };
        }
        getDataSelect({ ...searchDataTemp });
        setSearchData((Prev) => ({ ...Prev, ...searchDataTemp }));
    };

    const getK103 = async (data = {}) => {
        // console.log("data:", data);
        // console.log("district, subdistrict, hname", district, subdistrict, hname);

        try {
            setLoading(true);
            let res = await POST_GIS(DASHBOARDK103, {
                // amphur: data.amphur ?? amphur,
                amphur: data.amphur !== "ทั้งหมด" ? data.amphur : "",
                tambon: data.tambon !== "ทั้งหมด" ? data.tambon : "",
                hname: data.hname !== "ทั้งหมด" ? data.hname : "",
                quarter: data.quarter ?? quarter,
                year_quarter: data.year ?? year,
            });
            if (res.success) {
                setData(res.result.graph);
                setDataByQ(res.result.table);
                setHeaderByQ(res.result.header);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const getDataSelect = async (data = {}) => {
        // console.log("data:", data);
        try {
            let res = await POST_GIS(DASHBOARDSELECTDATA, { amphur: data.amphur ?? "", tambon: data.tambon ?? "", hname: location });
            if (res.success) {
                let obj = res.result;

                obj.district.unshift("ทั้งหมด");
                obj.subdistrict.unshift("ทั้งหมด");
                obj.Hname.unshift("ทั้งหมด");

                setDataAmphur([...obj.district]);
                setDataTambon([...obj.subdistrict]);
                setDataLocation([...obj.Hname]);
                if (profile.role_id !== 1 && profile.role_id !== 2) {
                    let dataTemp = { amphur: "", tambon: "", hname: "" };
                    if (obj.district) {
                        // setDataAmphur(obj.district);
                        dataTemp.amphur = obj.district[1];
                    }
                    if (obj.subdistrict) {
                        // setDataTambon(obj.subdistrict);
                        dataTemp.tambon = obj.subdistrict[1];
                    }
                    if (obj.Hname) {
                        // setDataLocation(obj.Hname);
                        dataTemp.hname = obj.Hname[1];
                    }
                    setSearchData((Prev) => ({ ...Prev, ...dataTemp }));
                    getK103({ ...data, ...dataTemp });
                } else {
                    getK103(data);
                }

                // console.log(n);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    // const handleCangeSelect = (e) => {
    //     let value = e.target.value;
    //     let name = e.target.name;

    //     if (name === "amphur") {
    //         setAmphur(value);
    //         setTambon("");
    //         setLocation("");
    //     }
    //     if (name === "tambon") {
    //         setTambon(value);
    //         setLocation("");
    //     }
    //     if (name === "location") {
    //         setLocation(value);
    //     }
    // };

    useEffect(() => {
        getDataSelect(searchData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <K103Card
                profile={profile}
                data={data}
                dataByQ={dataByQ}
                headerByQ={headerByQ}
                yearsData={yearsData}
                year={searchData.year}
                amphur={searchData.amphur}
                tambon={searchData.tambon}
                quarter={searchData.quarter}
                hname={searchData.hname}
                dataAmphur={dataAmphur}
                dataTambon={dataTambon}
                dataLocation={dataLocation}
                searchData={searchData}
                // handleCangeSelect={handleCangeSelect}
                setQuarter={setQuarter}
                setYear={setYear}
                setLocation={setLocation}
                onSearch={onSelect}
            />
        </Container>
    );
}

export default K103;
