import { Autocomplete, Box, FormControl, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import DataTable from "./DataTable";
import { headerTable } from "../../../untils/static";

function MonitorCard({
    data,
    dataLength,
    dataHosp,
    dateStart,
    setDateStart,
    searchLoca,
    setSearchLoca,
    setHcode,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
}) {
    // console.log('dataHosp:', dataHosp)
    // console.log("data:", data);

    const [cleared, setCleared] = useState(false);
    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => {};
    }, [cleared]);

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12} spacing={2}>
                    <Grid item xs={12} sm={12} md={12} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <Typography className="main-header">Monitor การนำเข้าข้อมูล 2</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Stack width="100%" direction="row" spacing={1}>
                            <Box display={{ xs: "none", sm: "block" }} sx={{ width: 100 }}>
                                <Typography mt={2} color="black">
                                    วันที่:
                                </Typography>
                            </Box>
                            <FormControl fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateStart)}>
                                    <DatePicker
                                        // fullWidth
                                        value={dateStart}
                                        slotProps={{
                                            field: { clearable: true, onClear: () => setCleared(true) },
                                        }}
                                        label="เลือกวันที่"
                                        format="DD/MM/YYYY"
                                        onChange={(e) => setDateStart(e)}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                        <Autocomplete
                            fullWidth
                            getOptionLabel={(option) => (typeof option === "string" ? option : `${option.hcode} ${option.hname}`)}
                            options={dataHosp}
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            value={searchLoca}
                            noOptionsText="No locations"
                            onChange={(event, newValue) => {
                                if (!newValue) {
                                    return;
                                }
                                if (newValue) {
                                    setSearchLoca(newValue);
                                    setHcode(newValue.hcode);
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="รพ.สต." />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <DataTable
                            header={headerTable.Monitor2}
                            data={data}
                            dataLength={dataLength}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            handleChangePage={handleChangePage}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

export default MonitorCard;
