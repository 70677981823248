import {
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import { StyledTableCell } from "../../../../Theme";
export default function GridTable({
    header,
    data,
    handleChangeCheckbox,
    checkAll,
    status,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
}) {
    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">
                                {status <= 2 &&
                                    (data.length > 0 ? (
                                        <FormControlLabel
                                            label="ทั้งหมด"
                                            control={
                                                <Checkbox
                                                    checked={checkAll === -1 ? true : false}
                                                    name="ทั้งหมด"
                                                    onChange={(e) => handleChangeCheckbox(e)}
                                                />
                                            }
                                        />
                                    ) : (
                                        <FormControlLabel
                                            label="ทั้งหมด"
                                            control={<Checkbox checked={false} name="ทั้งหมด" onChange={(e) => handleChangeCheckbox(e)} />}
                                        />
                                    ))}
                            </StyledTableCell>
                            {header.map((column, i) => (
                                <StyledTableCell key={i + 1} align={column.align} style={{ minWidth: column.minWidth }}>
                                    <Typography fontWeight="bold" fontSize="14px">
                                        {column.label}
                                    </Typography>
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                            let age_year = dayjs(new Date()).diff(dayjs(row.birth), "year");
                            let age_month = dayjs(new Date()).diff(dayjs(row.birth), "month");
                            return (
                                <TableRow key={i + 1}>
                                    <StyledTableCell align="left">
                                        {status <= 2 && (
                                            <FormControl component="fieldset" variant="standard">
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={(e) => handleChangeCheckbox(e)}
                                                                checked={row.check}
                                                                value={row.index}
                                                                name={row.seq}
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{row.hn ? row.hn : "-"}</StyledTableCell>
                                    <StyledTableCell align="left">
                                        {row.titlename} {row.fname} {row.lname}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">{age_year > 0 ? `${age_year} ปี` : `${age_month} เดือน`}</StyledTableCell>
                                    <StyledTableCell align="center">
                                        {row.message && (
                                            <Chip label={row.message} color={row.status_c >= 3 && row.status_c <= 5 ? "success" : "error"} />
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{row.date ? dayjs(row.date).format("DD/MM/YYYY") : "-"}</StyledTableCell>
                                    <StyledTableCell align="center">{row.hba1c ? row.hba1c : "-"}</StyledTableCell>
                                    <StyledTableCell align="center">{row.creatinine ? row.creatinine : "-"}</StyledTableCell>
                                    <StyledTableCell align="center">{row.potassium ? row.potassium : "-"}</StyledTableCell>
                                    <StyledTableCell align="center">{row.seq ? row.seq : "-"}</StyledTableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {data.length === 0 ? (
                <Grid container justifyContent="center" my={3}>
                    <Typography noWrap>* ไม่มีข้อมูลในตาราง *</Typography>
                </Grid>
            ) : (
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                />
            )}
        </Paper>
    );
}
