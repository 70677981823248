import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import BackdropLoad from "../../../components/Backdrop/BackdropLoad.js";
import {
    CHECKHTK,
    CHECKNEWAUTHCODE,
    CHECKSEAMLESS,
    CHECKSSS,
    GETLOCATIONBY,
    GETTOKENECLAIM,
    GETTOKENMCLAIM,
    POST,
    UPDATELOCATIONBY,
} from "../../../service";
import { getToken } from "../../../untils/shortcut.js";
import ClaimCodeCard from "./components/ClaimCodeCard.js";

function ClaimCode() {
    const profile = getToken(localStorage.getItem("profile"));

    const [loading, setLoading] = useState(false);
    //Eclaim
    const [emailEclaim, setEmailEclaim] = useState("");
    const [passwordEclaim, setPasswordEclaim] = useState("");
    const [tokenEClaim, setTokenEClaim] = useState("");
    const [msgEclaim, setMsgEclaim] = useState("");
    const [statusEclaim, setStatusEclaim] = useState("");
    const [loadingBtnEclaim, setLoadingBtnEclaim] = useState(false);
    const [showPasswordEClaim, setShowPasswordEClaim] = useState(false);
    //Moph
    const [emailMoph, setEmailMoph] = useState("");
    const [passwordMoph, setPasswordMoph] = useState("");
    const [tokenMoph, setTokenMoph] = useState("");
    const [msgMoph, setMsgMoph] = useState("");
    const [statusMoph, setStatusMoph] = useState("");
    const [loadingBtnMoph, setLoadingBtnMoph] = useState(false);
    const [showPasswordMoph, setShowPasswordMoph] = useState(false);
    //SSS
    const [emailSSS, setEmailSSS] = useState("");
    const [passwordSSS, setPasswordSSS] = useState("");
    const [tokenSSS, setTokenSSS] = useState("");
    const [statusSSS, setStatusSSS] = useState("");
    const [msgSSS, setMsgSSS] = useState("");
    const [loadingBtnSSS, setLoadingBtnSSS] = useState(false);
    const [showPasswordSSS, setShowPasswordSSS] = useState(false);
    //Seamless
    const [usernameSeamless, setUsernameSeamless] = useState("");
    const [passwordSeamless, setPasswordSeamless] = useState("");
    const [statusSeamless, setStatusSeamless] = useState("");
    const [msgSeamless, setMsgSeamless] = useState("");
    const [loadingBtnSeamless, setLoadingBtnSeamless] = useState(false);
    const [showPasswordSeamless, setShowPasswordSeamless] = useState(false);
    //KTB
    const [usernameHTK, setUsernameHTK] = useState("");
    const [passwordHTK, setPasswordHTK] = useState("");
    const [statusHTK, setStatusHTK] = useState("");
    const [msgHTK, setMsgHTK] = useState("");
    const [loadingBtnHTK, setLoadingBtnHTK] = useState(false);
    const [showPasswordHTK, setShowPasswordHTK] = useState(false);
    //New Auth Code
    const [usernameNewAuthCode, setUsernameNewAuthCode] = useState("");
    const [passwordNewAuthCode, setPasswordNewAuthCode] = useState("");
    const [statusNewAuthCode, setStatusNewAuthCode] = useState("");
    const [msgNewAuthCode, setMsgNewAuthCode] = useState("");
    const [loadingBtnNewAuthCode, setLoadingBtnNewAuthCode] = useState(false);
    const [showPasswordNewAuthCode, setShowPasswordNewAuthCode] = useState(false);

    const [mobileAPI, setMobileAPI] = useState("");
    const [kiosk, setKiosk] = useState("");

    const handleClickShowPassword = (setter) => () => setter((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    //HandleClickShowPassword for all
    const handleClickShowPasswordEClaim = handleClickShowPassword(setShowPasswordEClaim);
    const handleClickShowPasswordMoph = handleClickShowPassword(setShowPasswordMoph);
    const handleClickShowPasswordSSS = handleClickShowPassword(setShowPasswordSSS);
    const handleClickShowPasswordSeamless = handleClickShowPassword(setShowPasswordSeamless);
    const handleClickShowPasswordHTK = handleClickShowPassword(setShowPasswordHTK);
    const handleClickShowPasswordNewAuthCode = handleClickShowPassword(setShowPasswordNewAuthCode);

    const getDataClaim = useCallback(async () => {
        try {
            setLoading(true);
            let res = await POST(GETLOCATIONBY, { location_id: profile.location_id });
            if (res.success) {
                setEmailEclaim(res.result.e_username);
                setPasswordEclaim(res.result.e_password);
                setTokenEClaim(res.result.e_token);
                setEmailMoph(res.result.m_username);
                setPasswordMoph(res.result.m_password);
                setTokenMoph(res.result.m_token);
                setEmailSSS(res.result.sss_username);
                setPasswordSSS(res.result.sss_password);
                setTokenSSS(res.result.sss_token);
                setUsernameSeamless(res.result.seamless_username);
                setPasswordSeamless(res.result.seamless_password);
                setUsernameHTK(res.result.hp_ktb_username);
                setPasswordHTK(res.result.hp_ktb_password);
                setUsernameNewAuthCode(res.result.auth_username);
                setPasswordNewAuthCode(res.result.auth_password);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    }, [profile.location_id]);

    const getTokenEClaim = async () => {
        setMsgEclaim("");
        setStatusEclaim("");
        try {
            setLoadingBtnEclaim(true);
            let res = await POST(GETTOKENECLAIM, { username: emailEclaim, password: passwordEclaim });
            if (res.success) {
                setMsgEclaim(res.result.message);
                setStatusEclaim(res.result.status);
                if (res.result.status === true) {
                    setTokenEClaim(res.result.token);
                }
                setLoadingBtnEclaim(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoadingBtnEclaim(false);
        }
    };

    const getTokenMClaim = async () => {
        setMsgMoph("");
        setStatusMoph("");
        try {
            setLoadingBtnMoph(true);
            let res = await POST(GETTOKENMCLAIM, { username: emailMoph, password: passwordMoph });
            if (res.success) {
                setMsgMoph(res.result.message);
                setStatusMoph(res.result.status);
                if (res.result.status === true) {
                    setTokenMoph(res.result.token);
                }
                setLoadingBtnMoph(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoadingBtnMoph(false);
        }
    };

    const getTokenMSSS = async () => {
        setMsgSSS("");
        setStatusSSS("");
        try {
            setLoadingBtnSSS(true);
            let res = await POST(CHECKSSS, { sss_username: emailSSS, sss_password: passwordSSS });
            if (res.success) {
                setMsgSSS(res.result.message);
                setStatusSSS(res.result.status);
                if (res.result.status === true) {
                    setTokenSSS(res.result.token);
                    setLoadingBtnSSS(false);
                }
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoadingBtnSSS(false);
        }
    };

    const getTokenSeamless = async () => {
        setMsgSeamless("");
        setStatusSeamless("");
        try {
            setLoadingBtnSeamless(true);
            let res = await POST(CHECKSEAMLESS, { seamless_username: usernameSeamless, seamless_password: passwordSeamless });
            if (res.success) {
                setMsgSeamless(res.result.message);
                setStatusSeamless(res.result.status);
                setLoadingBtnSeamless(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoadingBtnSeamless(false);
        }
    };

    const getTokenHTK = async () => {
        setMsgHTK("");
        setStatusHTK("");
        try {
            setLoadingBtnHTK(true);
            let res = await POST(CHECKHTK, { hpKTB_username: usernameHTK, hpKTB_password: passwordHTK });
            if (res.success) {
                setMsgHTK(res.result.message);
                setStatusHTK(res.result.status);
                setLoadingBtnHTK(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoadingBtnHTK(false);
        }
    };

    const getNewAuthCode = async () => {
        setMsgNewAuthCode("");
        setStatusNewAuthCode("");
        setMobileAPI("");
        setKiosk("");
        try {
            setLoadingBtnNewAuthCode(true);
            let res = await POST(CHECKNEWAUTHCODE, { auth_username: usernameNewAuthCode, auth_password: passwordNewAuthCode });
            if (res.success) {
                setMsgNewAuthCode(res.result.message);
                setStatusNewAuthCode(res.result.status);
                setMobileAPI(res.result.mbAPI);
                setKiosk(res.result.kiosk_token);
                setLoadingBtnNewAuthCode(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoadingBtnNewAuthCode(false);
        }
    };

    const handleSubmitEclaim = async () => {
        try {
            setLoading(true);
            let data = [];
            data.push({
                e_username: emailEclaim,
                e_password: passwordEclaim,
                e_token: tokenEClaim,
            });
            let res = await POST(UPDATELOCATIONBY, { location_id: profile.location_id, data: data[0] });
            if (res.success) {
                Swal.fire({
                    icon: "success",
                    text: `บันทึกข้อมูลเรียบร้อย`,
                    confirmButtonText: "ตกลง",
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitMophClaim = async () => {
        try {
            setLoading(true);
            let data = [];
            data.push({
                m_username: emailMoph,
                m_password: passwordMoph,
                m_token: tokenMoph,
            });
            let res = await POST(UPDATELOCATIONBY, { location_id: profile.location_id, data: data[0] });
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    text: `บันทึกข้อมูลเรียบร้อย`,
                    confirmButtonText: "ตกลง",
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitSSS = async () => {
        try {
            setLoading(true);
            let data = [];
            data.push({
                sss_username: emailSSS,
                sss_password: passwordSSS,
                sss_token: tokenSSS,
            });
            let res = await POST(UPDATELOCATIONBY, { location_id: profile.location_id, data: data[0] });
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    text: `บันทึกข้อมูลเรียบร้อย`,
                    confirmButtonText: "ตกลง",
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitSeamless = async () => {
        try {
            setLoading(true);
            let data = [];
            data.push({
                seamless_username: usernameSeamless,
                seamless_password: passwordSeamless,
            });
            let res = await POST(UPDATELOCATIONBY, { location_id: profile.location_id, data: data[0] });
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    text: `บันทึกข้อมูลเรียบร้อย`,
                    confirmButtonText: "ตกลง",
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitHTK = async () => {
        try {
            setLoading(true);
            let data = [];
            data.push({
                hp_ktb_username: usernameHTK,
                hp_ktb_password: passwordHTK,
            });
            let res = await POST(UPDATELOCATIONBY, { location_id: profile.location_id, data: data[0] });
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    text: `บันทึกข้อมูลเรียบร้อย`,
                    confirmButtonText: "ตกลง",
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitNewAuthCode = async () => {
        try {
            setLoading(true);
            let data = [];
            data.push({ auth_username: usernameNewAuthCode, auth_password: passwordNewAuthCode, mobile_api: mobileAPI, kiosk_token: kiosk });
            let res = await POST(UPDATELOCATIONBY, { location_id: profile.location_id, data: data[0] });
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    text: `บันทึกข้อมูลเรียบร้อย`,
                    confirmButtonText: "ตกลง",
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getDataClaim();
    }, [getDataClaim]);

    return (
        <>
            <BackdropLoad loading={loading} />
            <ClaimCodeCard
                emailEclaim={emailEclaim}
                setEmailEclaim={setEmailEclaim}
                passwordEclaim={passwordEclaim}
                setPasswordEclaim={setPasswordEclaim}
                showPasswordEClaim={showPasswordEClaim}
                handleClickShowPasswordEClaim={handleClickShowPasswordEClaim}
                handleMouseDownPasswordEClaim={handleMouseDownPassword}
                getTokenEClaim={getTokenEClaim}
                loadingBtnEclaim={loadingBtnEclaim}
                loadingBtnMoph={loadingBtnMoph}
                loadingBtnSSS={loadingBtnSSS}
                msgEclaim={msgEclaim}
                statusEclaim={statusEclaim}
                emailMoph={emailMoph}
                setEmailMoph={setEmailMoph}
                passwordMoph={passwordMoph}
                setPasswordMoph={setPasswordMoph}
                showPasswordMoph={showPasswordMoph}
                handleClickShowPasswordMoph={handleClickShowPasswordMoph}
                handleMouseDownPasswordMoph={handleMouseDownPassword}
                getTokenMClaim={getTokenMClaim}
                msgMoph={msgMoph}
                statusMoph={statusMoph}
                emailSSS={emailSSS}
                setEmailSSS={setEmailSSS}
                passwordSSS={passwordSSS}
                setPasswordSSS={setPasswordSSS}
                showPasswordSSS={showPasswordSSS}
                handleClickShowPasswordSSS={handleClickShowPasswordSSS}
                handleMouseDownPasswordSSS={handleMouseDownPassword}
                getTokenMSSS={getTokenMSSS}
                msgSSS={msgSSS}
                statusSSS={statusSSS}
                handleSubmitEclaim={handleSubmitEclaim}
                handleSubmitMophClaim={handleSubmitMophClaim}
                handleSubmitSSS={handleSubmitSSS}
                handleSubmitSeamless={handleSubmitSeamless}
                handleSubmitHTK={handleSubmitHTK}
                handleClickShowPasswordSeamless={handleClickShowPasswordSeamless}
                handleMouseDownPasswordSeamless={handleMouseDownPassword}
                showPasswordSeamless={showPasswordSeamless}
                setShowPasswordSeamless={setShowPasswordSeamless}
                loadingBtnSeamless={loadingBtnSeamless}
                usernameSeamless={usernameSeamless}
                setUsernameSeamless={setUsernameSeamless}
                passwordSeamless={passwordSeamless}
                setPasswordSeamless={setPasswordSeamless}
                msgSeamless={msgSeamless}
                statusSeamless={statusSeamless}
                getTokenSeamless={getTokenSeamless}
                getTokenHTK={getTokenHTK}
                usernameHTK={usernameHTK}
                passwordHTK={passwordHTK}
                setUsernameHTK={setUsernameHTK}
                setPasswordHTK={setPasswordHTK}
                msgHTK={msgHTK}
                statusHTK={statusHTK}
                loadingBtnHTK={loadingBtnHTK}
                handleClickShowPasswordHTK={handleClickShowPasswordHTK}
                handleMouseDownPasswordHTK={handleMouseDownPassword}
                showPasswordHTK={showPasswordHTK}
                setPasswordNewAuthCode={setPasswordNewAuthCode}
                setUsernameNewAuthCode={setUsernameNewAuthCode}
                handleClickShowPasswordNewAuthCode={handleClickShowPasswordNewAuthCode}
                getNewAuthCode={getNewAuthCode}
                handleSubmitNewAuthCode={handleSubmitNewAuthCode}
                usernameNewAuthCode={usernameNewAuthCode}
                passwordNewAuthCode={passwordNewAuthCode}
                statusNewAuthCode={statusNewAuthCode}
                msgNewAuthCode={msgNewAuthCode}
                loadingBtnNewAuthCode={loadingBtnNewAuthCode}
                showPasswordNewAuthCode={showPasswordNewAuthCode}
                handleMouseDownPasswordNewAuthCode={handleMouseDownPassword}
                mobileAPI={mobileAPI}
                setMobileAPI={setMobileAPI}
                kiosk={kiosk}
                setKiosk={setKiosk}
            />
        </>
    );
}

export default ClaimCode;
