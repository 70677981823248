import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import { GETDATANOTPASSCLAIM, GETDATANOTPASSCLAIMSUB, POST, POST_GIS } from "../../service";
import { getToken } from "../../untils/shortcut";
import GridCard from "./DashboardNotPassClaim/GridCard";

function DashboardNotPassClaim() {
    const [dateStart, setDateStart] = useState(dayjs().startOf("year").utc(true));
    const [dateEnd, setDateEnd] = useState(dayjs());
    const [cleared, setCleared] = useState(false);
    const [dataNotPassClaim, setDataNotPassClaim] = useState([]);
    const [dataPassClaim, setDataPassClaim] = useState([]);
    const [hcode, setHcode] = useState("");
    const [hname, setHname] = useState("");
    const [dataHosp, setDataHosp] = useState([]);
    const [pageNotPass, setPageNotPass] = useState(0);
    const [rowsPerPageNotPass, setRowsPerPageNotPass] = useState(10);
    const [pagePass, setPagePass] = useState(0);
    const [rowsPerPagePass, setRowsPerPagePass] = useState(10);
    const [pageSub, setPageSub] = useState(0);
    const [rowsPerPageSub, setRowsPerPageSub] = useState(10);
    const [lengthNotPass, setLengthNotPass] = useState("");
    const [lengthPass, setLengthPass] = useState("");
    const [status, setStatus] = useState(0);
    const [detail, setDetail] = useState("");
    const [dataSub, setDataSub] = useState([]);
    const profile = getToken(localStorage.getItem("profile"));
    const [loading, setLoading] = useState(false);

    const handleChangePagePass = async (event, newPage) => {
        setPagePass(newPage);
    };

    const handleChangeRowsPerPagePass = (event) => {
        setRowsPerPagePass(event.target.value);
    };

    const handleChangePageNotPass = async (event, newPage) => {
        setPageNotPass(newPage);
    };

    const handleChangeRowsPerPageNotPass = (event) => {
        setRowsPerPageNotPass(event.target.value);
    };

    const getDataNotPassClaim = async () => {
        try {
            setLoading(true);
            let res = await POST_GIS(GETDATANOTPASSCLAIM, {
                rowperpage: rowsPerPageNotPass,
                page: pageNotPass + 1,
                hcode,
                date_start: dateStart,
                date_end: dateEnd,
            });
            if (res.success) {
                setLoading(false);
                if (profile.role_id !== 1 && profile.role_id !== 2) {
                    setDataNotPassClaim(res.result.opdNotPass[0]);
                    setDataHosp(res.result.selectorHospital[0]);
                    setLengthNotPass(res.result.lengthNotPass[0]);
                }
                setDataNotPassClaim(res.result.opdNotPass);
                setDataHosp(res.result.selectorHospital);
                setLengthNotPass(res.result.lengthNotPass);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `ค้นหาไม่สำเร็จ`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const getDataPassClaim = async () => {
        try {
            setLoading(true);
            let res = await POST_GIS(GETDATANOTPASSCLAIM, {
                rowperpage: rowsPerPagePass,
                page: pagePass + 1,
                hcode,
                date_start: dateStart,
                date_end: dateEnd,
            });
            if (res.success) {
                setLoading(false);
                if (profile.role_id !== 1 && profile.role_id !== 2) {
                    setDataPassClaim(res.result.opdPass[0]);
                    setDataHosp(res.result.selectorHospital[0]);
                    setLengthPass(res.result.lengthPass[0]);
                }
                setDataPassClaim(res.result.opdPass);
                setDataHosp(res.result.selectorHospital);
                setLengthPass(res.result.lengthPass);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `ค้นหาไม่สำเร็จ`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const getDataSub = async () => {
        try {
            setLoading(true);
            let res = await POST_GIS(GETDATANOTPASSCLAIMSUB, {
                rowperpage: rowsPerPageSub,
                page: pageSub + 1,
                hcode,
                date_start: dateStart,
                date_end: dateEnd,
                find: detail,
                status_claim: status,
            });
            if (res.success) {
                setLoading(false);
                if (profile.role_id !== 1 && profile.role_id !== 2) {
                    setDataSub(res.result.subData);
                }
                setDataSub(res.result.subData);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `ค้นหาไม่สำเร็จ`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    useEffect(() => {
        getDataNotPassClaim();
    }, [hcode, dateStart, dateEnd, rowsPerPageNotPass, pageNotPass]);

    useEffect(() => {
        getDataPassClaim();
    }, [hcode, dateStart, dateEnd, rowsPerPagePass, pagePass]);

    useEffect(() => {
        getDataSub();
    }, [hcode, dateStart, dateEnd, rowsPerPageSub, pageSub, detail, status]);

    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => {};
    }, [cleared]);

    return (
        <>
            <BackdropLoad loading={loading} />
            <GridCard
                dateStart={dateStart}
                dateEnd={dateEnd}
                setDateStart={setDateStart}
                setDateEnd={setDateEnd}
                cleared={cleared}
                setCleared={setCleared}
                profile={profile}
                dataNotPassClaim={dataNotPassClaim}
                hname={hname}
                setHname={setHname}
                hcode={hcode}
                setHcode={setHcode}
                dataHosp={dataHosp}
                handleChangePagePass={handleChangePagePass}
                handleChangeRowsPerPagePass={handleChangeRowsPerPagePass}
                handleChangePageNotPass={handleChangePageNotPass}
                handleChangeRowsPerPageNotPass={handleChangeRowsPerPageNotPass}
                rowsPerPageNotPass={rowsPerPageNotPass}
                pageNotPass={pageNotPass}
                rowsPerPagePass={rowsPerPagePass}
                pagePass={pagePass}
                lengthNotPass={lengthNotPass}
                lengthPass={lengthPass}
                dataPassClaim={dataPassClaim}
                getDataSub={getDataSub}
                setStatus={setStatus}
                setDetail={setDetail}
                dataSub={dataSub}
            />
        </>
    );
}

export default DashboardNotPassClaim;
