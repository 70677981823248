import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { BarElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(BarElement, Tooltip, Legend);

export default function BarChart({ auditPass, auditNotPass }) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (auditPass?.count !== undefined && auditNotPass?.count !== undefined) {
            setLoading(false);
        }
    }, [auditPass, auditNotPass]);

    const options = {
        responsive: true,
        plugins: {
            datalabels: {
                display: function (context) {
                    return context.chart.isDatasetVisible(context.datasetIndex);
                },
                formatter: function (value, context) {
                    const dataset = context.chart.data.datasets[context.datasetIndex];
                    const total = dataset.data.reduce((acc, curr) => acc + curr, 0);
                    const percentage = ((value / total) * 100).toFixed(1);
                    const label = context.dataIndex === 0 ? "ผ่าน" : "ไม่ผ่าน";
                    return `${label}: ${value} (${percentage}%)`;
                },
                align: "center",
                anchor: "end",
                color: "black",
                font: {
                    weight: "bold",
                },
                backgroundColor: "#eee",
                padding: 4,
            },
            title: {
                display: true,
                text: "",
            },
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                beginAtZero: true,
            },
        },
    };

    const data = {
        labels: ["จำนวน Audit ผ่าน", "จำนวน Audit ไม่ผ่าน"],
        datasets: [
            {
                data: [auditPass.count, auditNotPass.count],
                backgroundColor: ["rgba(27,94,32, 0.5)", "rgba(183,28,28, 0.5)"],
                borderColor: ["#1b5e20", "#b71c1c"],
                borderWidth: 1,
            },
        ],
    };

    return (
        <Grid container columns={12}>
            {loading ? (
                <Grid item xs={12}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Skeleton variant="rectangular" animation="wave" width="100%" height={400} />
                    </Box>
                </Grid>
            ) : auditPass.count !== 0 && auditNotPass.count !== 0 ? (
                <Bar data={data} options={options} />
            ) : (
                <Grid item xs={12} className="flex-center" style={{ minHeight: "400px", width: "100%" }}>
                    <Box className="census-info-empty">
                        <Typography>* ไม่มีข้อมูล *</Typography>
                    </Box>
                </Grid>
            )}
        </Grid>
    );
}
