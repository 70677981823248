import {
    Box,
    Chip,
    Grid,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";

import { visuallyHidden } from "@mui/utils";
import { StyledTableCell } from "../../../Theme";
import { hospitalStatus } from "../../../untils/static";

export default function GridTableList({ header, data, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage }) {
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("dt_update");

    function descendingComparator(a, b, orderBy) {
        // console.log(a, b, orderBy);
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        // console.log('order',order);
        // console.log('orderBy',orderBy);

        return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        // console.log(array, comparator);
        const stabilizedThis = array.map((el, index) => [el, index]);
        // console.log('stabilizedThis', stabilizedThis);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const visibleRows = useMemo(
        () => stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [order, orderBy, page, rowsPerPage, data]
        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
    );

    const handleRequestSort = (property) => (event) => {
        // console.log('event', event, property);
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {/* {header.map((column) => (
                                <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </StyledTableCell>
                            ))} */}
                            <StyledTableCell align="left" style={{ minWidth: 100 }}>
                                ชื่อสถานพยาบาล
                            </StyledTableCell>
                            {header.map((headCell) => (
                                <StyledTableCell key={headCell.id} align={headCell.align} sortDirection={orderBy === headCell.id ? order : false}>
                                    <TableSortLabel
                                        style={{ color: "#fff" }}
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : "asc"}
                                        onClick={handleRequestSort(headCell.id)}
                                    >
                                        {headCell.label}
                                        {orderBy === headCell.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === "desc" ? "sorted descending" : "sorted ascending"}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {visibleRows.map((row, index) => {
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            return (
                                <TableRow key={index}>
                                    <StyledTableCell align="left">
                                        {row.hcode} {row.hname}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{row.dt_update}</StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Chip
                                            sx={{ width: "130px", background: `${hospitalStatus[row.status].color}`, color: "var(--white)" }}
                                            label={hospitalStatus[row.status].label}
                                        />
                                    </StyledTableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {data.length === 0 ? (
                <Grid container justifyContent="center" my={3}>
                    <Typography noWrap>* ไม่มีข้อมูลในตาราง *</Typography>
                </Grid>
            ) : (
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                />
            )}
        </Paper>
    );
}
