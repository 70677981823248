import { Box, Grid, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import React, { useMemo, useState } from "react";
import { StyledTableCell } from "../../../Theme";

export default function GridTablePass({ dataPassClaim, header, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, lengthPass }) {
    const [order, setOrder] = useState(0);
    const [orderBy, setOrderBy] = useState("count");
    const direction = ["desc", "asc"];

    const sortDataTemp = (a, b, direction) => {
        if (direction === "asc") {
            return a - b;
        }
        if (direction === "desc") {
            return b - a;
        }
        return 0;
    };

    const visibleRows = useMemo(() => {
        const sortResult = [...dataPassClaim].sort((a, b) => {
            // console.log("dataPassClaim:", dataPassClaim);
            return sortDataTemp(Number(a[orderBy]), Number(b[orderBy]), direction[order]);
        });

        return sortResult;
    }, [order, orderBy, dataPassClaim, page, rowsPerPage]);

    const handleRequestSort = (property) => {
        setOrderBy(property);
        setOrder((prev) => {
            if (prev + 1 > 1) {
                return 0;
            }
            return prev + 1;
        });
    };

    return (
        <Paper sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                {/* {console.log("visibleRows:", visibleRows)} */}
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center" style={{ width: 1100 }}>
                                รายละเอียด
                            </StyledTableCell>
                            {header.map((headCell) => (
                                <StyledTableCell key={headCell.id} align={headCell.align} sortDirection={orderBy === headCell.id ? order : false}>
                                    <TableSortLabel
                                        style={{ color: "#fff" }}
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? direction[order] : "asc"}
                                        onClick={() => handleRequestSort(headCell.id)}
                                    >
                                        {headCell.label}
                                        {orderBy === headCell.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {direction[order] === "desc" ? "sorted descending" : "sorted ascending"}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {visibleRows.map((row, index) => {
                            return (
                                <TableRow key={index + 1}>
                                    <StyledTableCell align="left">{row.detail}</StyledTableCell>
                                    <StyledTableCell align="center">{row.count}</StyledTableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {dataPassClaim.length === 0 ? (
                <Grid container justifyContent="center" my={3}>
                    <Typography noWrap>* ไม่มีข้อมูลในตาราง *</Typography>
                </Grid>
            ) : (
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    page={page}
                    count={lengthPass}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                />
            )}
        </Paper>
    );
}
