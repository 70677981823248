import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, scales, Title, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { useState } from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export default function BarChartTopDiagnose({ topData }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const nextPage = () => {
        if (currentPage * itemsPerPage < topData.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = topData.slice(indexOfFirstItem, indexOfLastItem);

    const options = {
        indexAxis: "y",
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            datalabels: false,
            legend: {
                display: false,
            },
            tooltip: {
                intersect: false,
                callbacks: {
                    label: function (context) {
                        var label = context.dataset.label || "";
                        var value = context.formattedValue;
                        return `${label} : ${value} `;
                    },
                },
            },
        },
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                ticks: {
                    callback: function (value) {
                        const label = this.getLabelForValue(value);
                        return label?.length > 20 ? label.substr(0, 20).trim() + "..." : label;
                    },
                },
            },
        },
    };
    const labels = currentItems.map((e) => e.diagcode_th);
    const data = {
        labels,
        datasets: [
            {
                label: "จำนวน",
                data: currentItems.map((e) => e.count),
                borderColor: "rgb(54, 162, 235)",
                backgroundColor: "rgba(54, 162, 235, 0.5)",
            },
        ],
    };
    return (
        <Grid container columns={12}>
            <Grid item xs={12} className="flex-center" style={{ overflowX: "auto", overflowY: "auto", width: "100%", height: "700px" }}>
                {topData.length > 0 ? (
                    <>
                        <Box
                            sx={{
                                position: "relative",
                                width: "100%",
                                height: "700px",
                            }}
                        >
                            <Bar data={data} options={options} />

                            <Box
                                sx={{
                                    position: "absolute",
                                    bottom: 10,
                                    right: 20,
                                    zIndex: 1000,
                                }}
                            >
                                <Button
                                    onClick={prevPage}
                                    disabled={currentPage === 1}
                                    variant="outlined"
                                    sx={{
                                        marginRight: 2,
                                        padding: "8px 16px",
                                        minWidth: "40px",
                                    }}
                                >
                                    <ArrowBackIos />
                                </Button>
                                <Button
                                    onClick={nextPage}
                                    disabled={currentPage * itemsPerPage >= topData.length}
                                    variant="outlined"
                                    sx={{
                                        padding: "8px 16px",
                                        minWidth: "40px",
                                    }}
                                >
                                    <ArrowForwardIos />
                                </Button>
                            </Box>
                        </Box>
                    </>
                ) : (
                    <Box className="census-info-empty">
                        <Typography>* ไม่มีข้อมูล *</Typography>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
}
