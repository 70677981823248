import { Box, Checkbox, Grid, Stack, Typography } from "@mui/material";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useRef, useState } from "react";
import { GeoJSON, MapContainer, TileLayer } from "react-leaflet";
import MarkerJS from "./Marker.js";

const center = window.location.origin.includes("kk")
  ? [16.43192755724492, 102.82856609781523]
  : window.location.origin.includes("mdh")
    ? [16.59738946623057, 104.51104253757008]
    : window.location.origin.includes("spb")
      ? [14.651579065119135, 99.93989999520554]
      : [16.43192755724492, 102.82856609781523];

function MapChart({ data, subdistrictObj, group }) {
  const [map, setMap] = useState(null);
  const [showGeoJSON, setShowGeoJSON] = useState(true);
  const [showGeoJSON2, setShowGeoJSON2] = useState(true);
  const [showMarker, setShowMarker] = useState(false);
  const timer = useRef();
  const [numberid, SetNumberId] = useState({
    "01": true,
    "02": true,
    "03": true,
    "04": true,
    "05": true,
    "06": true,
    "07": true,
    "08": true,
    "09": true,
    "10": true,
    "11": true,
    "12": true,
    "13": true,
    "14": true,
    "15": true,
    "16": true
  })


  const popupFeature = (e) => {
    let layer = e.target;
    const { count, tam_th } = e.target.feature.properties;

    if (count > 0) {
      const popupOptions = {
        minWidth: 100,
        maxWidth: 250,
        className: "popup-classname",
      };

      layer
        .bindTooltip(() => {
          return `<b>${tam_th} : จำนวนผู้ป่วย ${count}</b>`;
        }, popupOptions)
        .openTooltip();
    }

    const originalStyle = {
      weight: layer.options.weight,
      color: layer.options.color,
      dashArray: layer.options.dashArray,
      fillOpacity: layer.options.fillOpacity,
    };

    layer.options.originalStyle = originalStyle;

    layer.setStyle({
      weight: 3,
      color: "#f00",
      dashArray: "",
      fillOpacity: 0.7,
    });
    layer.bringToFront();
  };

  const popupFeatureOut = (e) => {
    let layer = e.target;
    const originalStyle = layer.options.originalStyle;

    if (originalStyle) {
      layer.setStyle(originalStyle);
    }
    layer.bringToBack();
  };

  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: popupFeature,
      mouseout: popupFeatureOut,
    });
  };

  const mapPolygonColorToDensity = (d) => {
    if (d > 10000) return "#800026";
    if (d > 5000) return "#BD0026";
    if (d > 2000) return "#E31A1C";
    if (d > 1000) return "#FC4E2A";
    if (d > 500) return "#FD8D3C";
    if (d > 200) return "#FEB24C";
    if (d > 100) return "#FED976";
    if (d >= 1) return "#FFEDA0";
    return "#000";
  };

  const style = (feature) => {
    return {
      fillColor: mapPolygonColorToDensity(feature.properties.count),
      weight: 1,
      opacity: 1,
      color: "white",
      dashArray: "2",
      fillOpacity: 0.5,
      transition: "5s ease-in-out",
      className: "marker marker-fade-in",
    };
  };

  const filteredMarkers = data.filter((marker) => numberid[marker.codechronic]);

  useEffect(() => {
    if (map) {
      map.on("zoomend", () => {
        const currentZoom = map.getZoom();
        if (currentZoom < 12) {
          setShowGeoJSON(currentZoom < 12);
          setShowMarker(currentZoom >= 12);
          timer.current = setTimeout(() => {
            setShowGeoJSON2(true);
          }, 300);
        } else {
          setShowGeoJSON2(currentZoom < 12);
          setTimeout(() => {
            setShowGeoJSON(false);
            setShowMarker(true);
          }, 300);
        }
      });
    }
    return () => {
      clearTimeout(timer.current);
    };
  }, [map]);
  return (
    <>
      <Grid container columns={12}>
        <Grid container>
          {group.length > 1 && group.slice(1, group.length).map(e =>
            <Grid item xs={6} md={4} lg={3} xl={2}>
              <Typography>
                <Checkbox sx={{ padding: '2px 9px 9px 9px' }} defaultChecked checked={numberid[e.id]} onChange={(el) => SetNumberId({ ...numberid, [e.id]: el.target.checked })} color="success" />
                <label>
                  <img style={{ width: '20px', height: '20px' }} src={require(`../../../assets/images/location-pin (${e.id - 1}).png`)} alt="ไม่มีรูป" />
                  {e.type}
                </label>
              </Typography>
            </Grid>
          )}
        </Grid>
        <Box className="box-info" sx={{ background: "#333333" }} width="100%">
          <Stack spacing={1} className="flex-center" width="100%">
            <Typography style={{ margin: 5 }}>แผนที่แสดงจำนวนผู้ป่วยโรคเรื้อรังแบบดาวเทียม</Typography>
          </Stack>
        </Box>
        <Grid item xs={12} className={showGeoJSON2 ? "n" : ""}>
          <MapContainer
            scrollWheelZoom={true}
            center={center}
            zoom={9}
            className="mapContainer"
            ref={setMap}
            attributionControl={false}
          // fadeAnimation={true}
          // markerZoomAnimation={true}
          // zoomAnimation={true}
          // zoomAnimationThreshold={100}
          // zoomSnap={0.25}
          // zoomDelta={0.25}
          >
            <TileLayer
              // ตรวจสอบสถานะ satelliteView
              maxZoom={22}
              minZoom={8}
              maxNativeZoom={19}
              url={"https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"}
            />
            {showGeoJSON && (
              <GeoJSON
                data={subdistrictObj}
                style={(e) => ({
                  ...style(e),
                })}
                onEachFeature={onEachFeature}
              />
            )}

            {showMarker && <MarkerJS filteredMarkers={filteredMarkers} />}
            {/* <MarkerJS filteredMarkers={filteredMarkers} /> */}

          </MapContainer>
        </Grid>
      </Grid>
    </>
  );
}

export default MapChart;