import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import Modal from "@mui/material/Modal";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ModalStepper from "../../components/ModalStepper";
import { GET, GETTYPE } from "../../service";
import { useRoute } from "../../state/ManageRoute";
// import { ItemHomepage } from "../../ThemeTraining";
import { ItemHomepage } from "../../Theme";

function Homepage() {
    const navigate = useNavigate();
    const screen = JSON.parse(localStorage.getItem("screen"));
    const [type, setType] = useState([]);
    const [objScreen, setObjScreen] = useState({});
    // console.log(screen);
    let intersections_home = screen.filter((e) => e.type === 0);

    const getType = async () => {
        try {
            let res = await GET(GETTYPE);
            if (res.success) {
                let obj = res.result.filter((e) => Number(e.type) !== 0);
                setType(obj);
            } else {
                Swal.fire({
                    title: "warning",
                    text: `${res.message}`,
                    icon: "warning",
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            console.log("Error caught:", error);
            Swal.fire({
                title: "warning",
                text: error.message || error,
                icon: "warning",
                showConfirmButton: true,
            });
        }
    };

    const [setMenu, setSystem] = useRoute((state) => [state.setMenu, state.setSystem]);

    const handleRoute = (path, menu, system) => {
        let objMenu = menu[0];

        setMenu(menu);
        setSystem(system);

        if (objMenu.sub) {
            navigate(objMenu.submenu[0].url);
        } else {
            navigate(path);
        }
    };
    const popup = window.localStorage.getItem("popup");
    const [open, setopen] = useState(popup ? dayjs(new Date()).format("YYYY-MM-DD") >= popup : true);

    useEffect(() => {
        getType();
        setMenu(intersections_home);
        setSystem("หน้าหลัก");
    }, []);

    useEffect(() => {
        const separatedByType = screen.reduce((acc, item) => {
            const type = item.type;
            // console.log("type:", type);
            acc[type] = acc[type] ? [...acc[type], item] : [item];
            return acc;
        }, {});

        setObjScreen(separatedByType);
    }, []);

    return (
        <>
            <Container maxWidth={false} sx={{ position: "relative", paddingBottom: "120px" }}>
                <Modal open={open} onClose={() => setopen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            minWidth: 350,
                            maxWidth: 1200,
                            bgcolor: "background.paper",
                            borderRadius: "5px",
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        <ModalStepper handleClose={() => setopen(false)} />
                    </Box>
                </Modal>
                <Box className="flex-center" minHeight="50vh" sx={{ my: { xs: "5%", sm: "none" } }}>
                    <Grid container columns={12} className="flex-center" spacing={3}>
                        <Grid item xs={12}>
                            <Grid container columns={12} className="flex-start" spacing={3}>
                                {type.map(
                                    (e, i) =>
                                        objScreen[e.type] && (
                                            <Grid key={i} item xs={12} sm={4} md={4}>
                                                <ItemHomepage
                                                    elevation={3}
                                                    className="flex-center"
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        handleRoute(objScreen[e.type][0].url, objScreen[e.type], e.screen_name);
                                                    }}
                                                >
                                                    <Stack direction="row" spacing={1} className="flex-start">
                                                        <i className={e.icon} style={{ color: "var(--teal-A400)" }}></i>
                                                        <Typography className="menu-homepage">{e.screen_name}</Typography>
                                                    </Stack>
                                                </ItemHomepage>
                                            </Grid>
                                        )
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <footer>
                <svg viewBox="0 -20 700 110" width="100%" height="110" preserveAspectRatio="none">
                    <path
                        transform="translate(0, -20)"
                        d="M0,10 c80,-22 240,0 350,18 c90,17 260,7.5 350,-20 v50 h-700"
                        fill="var(--teal-100)"
                        opacity="0.8"
                    />
                    <path d="M0,10 c80,-18 230,-12 350,7 c80,13 260,17 350,-5 v100 h-700z" fill="var(--teal-A700)" />
                </svg>
            </footer>
        </>
    );
}

export default Homepage;
