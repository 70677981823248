import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import React from "react";

import { HospitalIcon } from "../../../untils/icons";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { headerTable, select_data } from "../../../untils/static";
import GridTableList from "./GridTableList";
dayjs.locale("th");

function ListHospital(props) {
    // console.log("props:", props);
    let select = select_data.status_hosp;
    // console.log("select:", select);
    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={12} className="flex-start">
                        <Stack direction="row" spacing={2} mb={2}>
                            <HospitalIcon fontSize="medium" />
                            <Typography className="main-header">สถานะการดึงข้อมูล</Typography>
                        </Stack>
                    </Grid>
                    <Grid container columns={12} spacing={3} className="flex-start" mb={2}>
                        <Grid item xs={7} sm={4} md={3}>
                            <TextField
                                fullWidth
                                id="search"
                                label="ค้นหารายการ"
                                type="text"
                                variant="outlined"
                                size="medium"
                                value={props.search}
                                onChange={(e) => {
                                    props.setSearch(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={7} sm={4} md={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    defaultValue={props.dateStart}
                                    value={props.dateStart}
                                    slotProps={{
                                        field: { clearable: true, onClear: () => props.setDateStart(null) },
                                    }}
                                    name="date"
                                    label="วันที่"
                                    format="DD/MM/YYYY"
                                    onChange={(e) => {
                                        props.setDateStart(e);
                                        // console.log({ e });
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={7} sm={4} md={3}>
                            <FormControl fullWidth>
                                <InputLabel>เลือกสถานะ</InputLabel>
                                <Select name="status" value={props.status} label="เลือกสถานะ" onChange={(e) => props.setStatus(e.target.value)}>
                                    {select.map((e, i) => (
                                        <MenuItem key={i + 1} value={e.value} disabled={e.disabled}>
                                            {e.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} sm={12} md={12}>
                        <Grid item xs={12} sm={12} md={12} className="flex-end">
                            <Stack direction="row">
                                <Button variant="contained" color="success" size="large" onClick={props.genExcel}>
                                    Excel
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} mt={2}>
                        <GridTableList
                            header={headerTable.hospital_status}
                            data={props.data}
                            page={props.page}
                            rowsPerPage={props.rowsPerPage}
                            handleChangePage={props.handleChangePage}
                            handleChangeRowsPerPage={props.handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

export default ListHospital;
