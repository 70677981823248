import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box, Grid, IconButton, Skeleton, Typography } from "@mui/material";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export default function BarChartHos({ information }) {
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        if (information && information.length > 0) {
            setLoading(false);
        }
    }, [information]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = information.slice(indexOfFirstItem, indexOfLastItem);

    const nextPage = () => {
        if (currentPage * itemsPerPage < information.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const options = {
        indexAxis: "x",
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                display: function (context) {
                    return context.chart.isDatasetVisible(context.datasetIndex);
                },
                borderRadius: 4,
                align: "end",
                anchor: "end",
                color: "black",
                backgroundColor: "#BEBEBD",
            },
            title: {
                display: true,
                text: "",
            },
            legend: {
                display: false,
            },
        },
    };

    const modifyHospitalName = (name) => {
        return name.replace("โรงพยาบาลส่งเสริมสุขภาพตำบล", "").trim();
    };

    const labels = currentItems.map((e) => modifyHospitalName(e.hname));

    const data = {
        labels,
        datasets: [
            {
                label: "คน",
                data: currentItems.map((e) => e.count),
                backgroundColor: "rgba(54, 162, 235, 0.5)",
                borderColor: "rgba(54, 162, 235)",
            },
        ],
    };

    return (
        <Grid container columns={12}>
            <Grid item xs={12} className="flex-center">
                {loading ? (
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                        <Skeleton variant="rectangular" animation="wave" width="100%" height="100%" />
                    </Box>
                ) : currentItems.length > 0 ? (
                    <Box sx={{ display: "relative", alignItems: "center", justifyContent: "center", width: "100%", flexDirection: "row" }}>
                        <Box sx={{ minHeight: "500px", width: "100%", height: "100%", display: "flex", justifyContent: "center" }}>
                            <Bar data={data} options={options} />
                        </Box>
                        <Box className="flex-start" justifyContent="space-between">
                            <IconButton onClick={prevPage} disabled={currentPage === 1}>
                                <ArrowBackIos />
                            </IconButton>
                            <IconButton onClick={nextPage} disabled={currentPage * itemsPerPage >= information.length}>
                                <ArrowForwardIos />
                            </IconButton>
                        </Box>
                    </Box>
                ) : (
                    <Box sx={{ textAlign: "center" }}>
                        <Typography variant="h6" color="text.secondary">
                            * ไม่มีข้อมูล *
                        </Typography>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
}
