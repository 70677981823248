import React, { useEffect, useState } from "react";

import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { ClaimIcon, SendIcn } from "../../../../untils/icons";

import { AccessTime, Cancel, CheckCircle, Pending } from "@mui/icons-material";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import dayjs from "dayjs";
import { ButtonConfirm, ButtonTeal, ItemDashboardBlue, ItemDashboardGreen, ItemDashboardPurple, ItemDashboardRed } from "../../../../Theme";
import GridTable from "./GridTable";

function ClaimCard({
    dateStart,
    dateEnd,
    setDateEnd,
    setDateStart,
    header,
    data,
    handleChangeCheckbox,
    checkAll,
    handleSendClaim,
    setStatus,
    status,
    search,
    setSearch,
    handleStatusClaim,
    statusVaccine,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
}) {
    // require('dayjs/locale/th')
    const [cleared, setCleared] = useState(false);
    const len = data.filter((e) => e.status === 1);

    const count = statusVaccine.map((e) => e.count);
    const label = statusVaccine.map((e) => e.label);

    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => {};
    }, [cleared]);

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12} spacing={3}>
                    <Grid item xs={12} mt={1} mb={3} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <ClaimIcon fontSize="medium" />
                            <Typography
                                className="main-header"
                                style={{ textDecoration: "underline", cursor: "pointer" }}
                                onClick={() => window.open("https://www.healthplatform.krungthai.com/healthPlatform/login")}
                            >
                                ส่งออกข้อมูลวัคซีนไข้หวัดใหญ่
                            </Typography>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container columns={12} spacing={3} className="flex-start">
                            <Grid item xs={6} sm={6} md={4}>
                                <Stack width="100%" direction={{ xs: "column", sm: "row", md: "row" }} spacing={1}>
                                    <Box display={{ xs: "none", sm: "block" }} sx={{ width: 100 }}>
                                        <Typography mt={2} color="var(--teal-A800)">
                                            จนถึง :
                                        </Typography>
                                    </Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateStart)}>
                                            <DatePicker
                                                // fullWidth
                                                value={dateStart}
                                                slotProps={{
                                                    field: {
                                                        clearable: true,
                                                        onClear: () => setCleared(true),
                                                    },
                                                }}
                                                label="วันที่เริ่มต้น"
                                                format="DD/MM/YYYY"
                                                onChange={(e) => setDateStart(e)}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4}>
                                <Stack width="100%" direction={{ xs: "column", sm: "row", md: "row" }} spacing={1}>
                                    <Box display={{ xs: "none", sm: "block" }} sx={{ width: 100 }}>
                                        <Typography mt={2} color="var(--teal-A800)">
                                            จนถึง :
                                        </Typography>
                                    </Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateEnd)}>
                                            <DatePicker
                                                fullWidth
                                                value={dateEnd}
                                                slotProps={{
                                                    field: {
                                                        clearable: true,
                                                        onClear: () => setCleared(true),
                                                    },
                                                }}
                                                label="วันที่สิ้นสุด"
                                                format="DD/MM/YYYY"
                                                onChange={(e) => setDateEnd(e)}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} my={2}>
                                <FormControl fullWidth>
                                    <InputLabel>เลือกสถานะเคลม</InputLabel>
                                    <Select
                                        name=""
                                        value={status}
                                        label="เลือกสถานะเคลม"
                                        displayEmpty
                                        required
                                        onChange={(e) => setStatus(e.target.value)}
                                    >
                                        {dataPreAudit &&
                                            dataPreAudit.map((el, i) => (
                                                <MenuItem key={i + 1} value={el.value} disabled={el.disabled}>
                                                    {el.label}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="search"
                            label="ค้นหารายการ"
                            type="search"
                            variant="outlined"
                            size="medium"
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} className="flex-end">
                        {/* {status > 2 && (
                            <ButtonConfirm onClick={handleStatusClaim} sx={{ mr: 2 }}>
                                <CreditScoreIcon fontSize="small" />
                                &nbsp;
                                <Typography sx={{ cursor: "pointer" }}>ติดตามการเคลม</Typography>
                            </ButtonConfirm>
                        )} */}
                        {status <= 2 && (
                            <>
                                <Typography sx={{ cursor: "pointer", mr: 2 }}>ส่งออกข้อมูล {len.length} รายการ</Typography>
                                {/* <ButtonConfirm onClick={handleStatusClaim} sx={{ mr: 2 }}>
                                    <CreditScoreIcon fontSize="small" />
                                    &nbsp;
                                    <Typography sx={{ cursor: "pointer" }}>ติดตามการเคลม</Typography>
                                </ButtonConfirm> */}
                                <ButtonTeal onClick={handleSendClaim}>
                                    <SendIcn fontSize="small" />
                                    &nbsp;
                                    <Typography sx={{ cursor: "pointer" }}>ส่งออกข้อมูล</Typography>
                                </ButtonTeal>
                            </>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} mb={5}>
                        <GridTable
                            header={header}
                            data={data}
                            handleChangeCheckbox={handleChangeCheckbox}
                            checkAll={checkAll}
                            status={status}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Grid>
                    {status <= 2 && (
                        <>
                            <Grid item xs={12} className="flex-end">
                                <Typography sx={{ cursor: "pointer", mr: 2 }}>ส่งออกข้อมูล {len.length} รายการ</Typography>
                                <ButtonTeal onClick={handleSendClaim}>
                                    <SendIcn fontSize="small" />
                                    &nbsp;
                                    <Typography sx={{ cursor: "pointer" }}>ส่งออกข้อมูล</Typography>
                                </ButtonTeal>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Paper>
        </Box>
    );
}

export default ClaimCard;

// const dataClaim = [
//      { label: "เลือกประเภทสิทธิ์", value: "", disabled: true },
//      { label: "สิทธ์องค์กรการปกครองส่วนท้องถิ่น (อปท.)", value: 1 },
//      { label: "สิทธิ์บัตรทอง", value: 2 },
// ];

const dataPreAudit = [
    { label: "เลือกสถานะเคลม", value: "", disabled: true },
    { label: "รอส่ง", value: 1 },
    // { label: "ส่งไม่ผ่าน", value: 2 },
    { label: "กำลังตรวจสอบ", value: 2, color: "var(--yellow)" },
    { label: "ผ่านการตรวจสอบ", value: 3, color: "success" },
    { label: "ไม่ผ่านการตรวจสอบ", value: 4, color: "error" },
];
