import React from "react";
import { Button, Chip, Grid, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";

import { StyledTableCell } from "../../../Theme";

export default function DataTable({ header, data = [], dataLength, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage }) {
    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {header.map((column) => (
                                <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            return (
                                <TableRow key={index + 1}>
                                    <StyledTableCell align="left">{`${row.hcode || "ไม่พบข้อมูล รพสต."} ${row.hname || ""}`}</StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center">{row.responses}</StyledTableCell>
                                    <StyledTableCell align="center">{row.table}</StyledTableCell>
                                </TableRow>
                            );
                        })}
                        <TableRow style={{ backgroundColor: "#eeeeee", color: "#fff", position: "sticky", bottom: 0, zIndex: 1 }}>
                            <StyledTableCell align="center">ทั้งหมด</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell align="center">{data.reduce((sum, row) => sum + (Number(row.responses) || 0), 0)}</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {data.length === 0 ? (
                <Grid container justifyContent="center" my={3}>
                    <Typography noWrap>* ไม่มีข้อมูลในตาราง *</Typography>
                </Grid>
            ) : (
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100, 500, 1000]}
                    component="div"
                    count={dataLength}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                />
            )}
        </Paper>
    );
}
