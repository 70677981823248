import { ReactFlow, ReactFlowProvider, useEdgesState, useNodesState } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import React, { useEffect } from "react";
import "../../../css/custom-tree.css";
import ImageNode from "./ImageNode";
import dagre from "dagre";
import { useMediaQuery } from "react-responsive";

const y = [0, 400, 800, 1200];

export default function HRFamilyTree({ dataTree }) {
    // console.log("dataTree:", dataTree);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const profile = localStorage.getItem("profile");
    const token = localStorage.getItem("uuid");
    const pathapi = process.env.REACT_APP_SERVICE;
    const nodeTypes = {
        imageNode: ImageNode,
    };
    const snapGrid = [20, 20];

    const isXs = useMediaQuery({ query: "(max-width: 600px)" });
    const isSm = useMediaQuery({ query: "(max-width: 1024px)" });
    const isMd = useMediaQuery({ query: "(max-width: 1366px)" });

    // กำหนด defaultViewport ตามขนาดหน้าจอ
    const getDefaultViewport = () => {
        if (isXs) {
            return { x: -30, y: -25, zoom: 0.3 }; // สำหรับหน้าจอขนาดเล็ก
        } else if (isSm) {
            return { x: -75, y: -75, zoom: 0.6 }; // สำหรับหน้าจอขนาดกลาง
        } else if (isMd) {
            return { x: -100, y: -100, zoom: 0.8 }; // สำหรับหน้าจอขนาดใหญ่
        } else {
            return { x: -130, y: -100, zoom: 1 }; // สำหรับหน้าจอใหญ่
        }
    };

    const flattenTree = (data) => {
        let flatNodes = [];
        let flatEdges = [];
        let levelMap = {};

        const processNode = (node, parentId = null, level = 0, xPos = 0) => {
            const yPos = y[level];
            const xOffset = parentId ? xPos * 250 : 0;

            flatNodes.push({
                id: node.staff_id.toString(),
                type: "imageNode",
                data: {
                    label: node.name,
                    position: node.position,
                    image_src: `${pathapi}${node.imgpath}?secret_key=${token}&data=${profile}`,
                },
                position: { x: xOffset, y: yPos },
                targetPosition: "bottom",
                draggable: false,
            });

            if (parentId) {
                flatEdges.push({
                    id: `e${parentId}-${node.staff_id}`,
                    source: parentId.toString(),
                    target: node.staff_id.toString(),
                    type: "step",
                    animated: false,
                    style: { stroke: "#CBCBCB", strokeWidth: 1 },
                });
            }

            if (node.children && node.children.length > 0) {
                const childrenCount = node.children.length;
                const childSpacing = 2;

                node.children.forEach((child, index) => {
                    const childXPos = (index - Math.floor(childrenCount / 2)) * childSpacing;
                    processNode(child, node.staff_id, level + 1, childXPos);
                });
            }
        };

        data.forEach((rootNode) => processNode(rootNode));

        return { nodes: flatNodes, edges: flatEdges };
    };

    const applyLayout = (nodes, edges) => {
        const g = new dagre.graphlib.Graph();
        g.setGraph({ rankdir: "TB" });
        g.setDefaultEdgeLabel(() => ({}));

        nodes.forEach((node) => {
            g.setNode(node.id, { width: 250, height: 250 });
        });

        edges.forEach((edge) => {
            g.setEdge(edge.source, edge.target);
        });

        dagre.layout(g);

        return nodes.map((node) => {
            const { x, y } = g.node(node.id);
            return {
                ...node,
                position: { x, y },
            };
        });
    };

    useEffect(() => {
        if (dataTree && dataTree.length > 0) {
            const { nodes: newNodes, edges: newEdges } = flattenTree(dataTree);

            const layoutedNodes = applyLayout(newNodes, newEdges);

            setNodes(layoutedNodes);
            setEdges(newEdges);
        } else {
            const { nodes: newNodes, edges: newEdges } = flattenTree([]);

            const layoutedNodes = applyLayout(newNodes, newEdges);

            setNodes(layoutedNodes);
            setEdges(newEdges);
        }
    }, [dataTree]);

    return (
        <ReactFlowProvider>
            <div style={{ height: "70vh" }}>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    nodeTypes={nodeTypes}
                    snapToGrid={true}
                    snapGrid={snapGrid}
                    // fitView={true}
                    maxZoom={1}
                    minZoom={0}
                    defaultViewport={getDefaultViewport()}
                ></ReactFlow>
            </div>
        </ReactFlowProvider>
    );
}
